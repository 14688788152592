import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IHomeActive from '@ifca-root/react-component/src/assets/icons/home-w.svg'
import IHome from '@ifca-root/react-component/src/assets/icons/home.svg'
import ILogOutActive from '@ifca-root/react-component/src/assets/icons/logout-w.svg'
import ILogOut from '@ifca-root/react-component/src/assets/hrIcons/log-out.svg'
import MyMenu from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/my-menu.svg'
import SubordinateSvg from '@ifca-root/react-component/src/assets/icons/subordinate.svg'
import ProjectExpenseManagement from '@ifca-root/react-component/src/assets/icons/Project Expense Management/Project Main Menu/expense-claim.svg'
import ClaimManagement from '@ifca-root/react-component/src/assets/icons/Claim Management/Claim Main Menu/claim.svg'
import RequisitionManagement from '@ifca-root/react-component/src/assets/icons/Requisition Managment/requisition.svg'
import SystemAdmin from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/system-admin.svg'
import PersonnelManagement from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/personnel-management.svg'
import PayrollManagement from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/payroll-management.svg'
import LeaveManagement from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/leave.svg'
import TimeAttendance from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/time-attendance.svg'
import Timesheet from '@ifca-root/react-component/src/assets/icons/Timesheet/TS Main Menu/timesheet.svg'
import StaffTraining from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/staff-training.svg'
import StaffAppraisal from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/staff-appraisal.svg'
import BusinessInsight from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/business-insight.svg'
import DigitalReporting from '@ifca-root/react-component/src/assets/icons/Employee Super App/EMP Side Menu/digital-reporting.svg'
import Logout from '@ifca-root/react-component/src/assets/icons/logout.svg'
import Profile from 'assets/icons/profile.svg'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import Authentication from '@ifca-root/react-component/src/assets/icons/authentication.svg'
import {
  HrPermission,
  useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery,
  useGetRoleByUserIdLazyQuery,
  useGetRoleByUserIdQuery,
  useGetRolePermissionByUserLazyQuery,
  useGetRoleQuery,
  useIsSuperUserEmployeeLazyQuery,
  useLoggedInUserProfileQuery,
  useMyEmployeeInfoLazyQuery,
  usePersonnelParameterListingQuery,
  useUserProfilePicLazyQuery,
  useLoginUserPermsQuery,
} from 'generated/graphql'
import { useLazyQuery } from '@apollo/react-hooks'
import {
  GET_ACCOUNT_DETAIL,
  GET_PACKAGES_DETAIL,
} from '../../../src/containers/HomeModule/AccountQuery'
import { AccountClient } from '../../client'

export const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  })
)

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  open: boolean
  variant: any
  onCloseDrawer: any
}

export default function LayoutDrawer(props: Props) {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const { window, open, onCloseDrawer, variant, ...rest } = props
  const classes = useStyles()
  const container =
    window !== undefined ? () => window().document.body : undefined
  let history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const EmployeeDetails = JSON.parse(localStorage.getItem('Employee'))
  const [myLeave, setMyLeave] = useState(false)
  const [myClaim, setMyClaim] = useState(false)
  const [payroll, setPayroll] = useState(false)
  const [picture, setPicture] = useState(
    EmployeeDetails?.Documents?.DocumentFile
  )
  const [myRequisition, setMyRequisition] = useState(false)
  const [myTimesheet, setMyTimesheet] = useState(false)
  const [projectExpense, setProjectExpense] = useState(false)

  const [myPerformance, setPerformance] = useState(false)
  const [myTMS, setMyTMS] = useState(false)

  const [myTraining, setTraining] = useState(false)
  const [selectedFile, setSelectedFile] = useState({ files: [] })
  const fileChangedHandler = event => {
    let nextState = { ...selectedFile }
    nextState.files.splice(0, nextState.files.length)
    nextState.files.push(event.target.files[0])
    setSelectedFile(nextState)
    let reader = new FileReader()
    reader.onloadend = () => {
      setPicture(reader.result)
      // setRender(true)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const {
    loading: userLoading,
    called: isUserCalled,
    data: { loggedInUserProfile } = { loggedInUserProfile: null },
  } = useLoggedInUserProfileQuery({ fetchPolicy: 'no-cache' })

  const {} = useLoginUserPermsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      localStorage.setItem('userAccess', data?.LoginUserPerms)
    },
  })

  const [
    getUserProfilePic,
    { data: UserProfilePic },
  ] = useUserProfilePicLazyQuery({
    variables: {
      UserID: loggedInUserProfile?.isEmployee ? loggedInUserProfile?.employeeID : loggedInUserProfile?.ID,
      IsEmployee: loggedInUserProfile?.isEmployee,
    },
    fetchPolicy: 'no-cache',
  })

  const [getPackagesDetail, { data: packageData }] = useLazyQuery(
    GET_PACKAGES_DETAIL,
    {
      client: AccountClient,
      variables: {
        softwareCode: 'HR',
        accountID: loggedInUserProfile?.accountID,
      },
    }
  )

  const [
    getRoleData,
    {
      loading: roleLoading,
      called: roleCalled,
      data: { getRole } = { getRole: [] },
    },
  ] = useGetRoleByUserIdLazyQuery({
    variables: {
      UserID: loggedInUserProfile?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getIsSuperUserEmpData,
    {
      loading: superLoading,
      called: superCalled,
      data: { IsSuperUserEmployee } = { IsSuperUserEmployee: false },
    },
  ] = useIsSuperUserEmployeeLazyQuery({
    variables: {
      EmployeeID: loggedInUserProfile?.employeeID,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    getRolePermissionByUserData,
    {
      loading: aclLoading,
      called: aclCalled,
      data: { getRolePermissionByUser } = {
        getRolePermissionByUser: [],
      },
    },
  ] = useGetRolePermissionByUserLazyQuery({
    variables: {
      hrPermissions: [
        HrPermission.HomeAdminExecSummaryRead,
        HrPermission.HomePersonnelExecSummaryRead,
        HrPermission.HomeClaimExecSummaryRead,
        HrPermission.HomePayrollExecSummaryRead,
        HrPermission.HomeLeaveExecSummaryRead,
        HrPermission.HomeRequisitionExecSummaryRead,
        HrPermission.HomeTmsExecSummaryRead,
        HrPermission.HomeTimesheetRead,
        HrPermission.HomeDigitalReportingView,
        HrPermission.HomeProjectExecSummaryRead,
      ],
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (packageData) {
      for (var obj in packageData) {
        var data = packageData[obj]
        for (var d in data) {
          if (data[d] === 'CLAIMMANAGEMENT') {
            setMyClaim(true)
          } else if (data[d] === 'PAYROLL') {
            setPayroll(true)
          } else if (data[d] === 'LEAVEMANAGEMENT') {
            setMyLeave(true)
          } else if (data[d] === 'REQUISITIONMANAGEMENT') {
            setMyRequisition(true)
          } else if (data[d] === 'TIMESHEET') {
            setMyTimesheet(true)
          } else if (data[d] === 'TRAININGDEVELOPMENT') {
            setTraining(true)
          } else if (data[d] === 'TIMEATTENDANCE') {
            setMyTMS(true)
          } else if (data[d] === 'PROJECTEXPENSEMANAGEMENT') {
            setProjectExpense(true)
          }
        }
      }
    }
  }, [packageData])

  useEffect(() => {
    if (loggedInUserProfile) {
      getUserProfilePic()
      getPackagesDetail()
      getRoleData()
      getRolePermissionByUserData()

      if (loggedInUserProfile?.employeeID) {
        getIsSuperUserEmpData()
      }
    }
  }, [loggedInUserProfile])


  const [
    LoadEmployeeAppSecurityPermissionsByContactID,
    {
      data: { GetEmployeeAppSecurityPermissionsByContactID } = {
        GetEmployeeAppSecurityPermissionsByContactID: [],
      },
      loading,
    },
  ] = useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    setTimeout(() => {
      LoadEmployeeAppSecurityPermissionsByContactID({
        variables: {
          ContactID: '',
          Permissions: [
            HrPermission.HomeMymenuRead,
            HrPermission.HomeMysubordinateRead,
          ],
          EmployeeID: loggedInEmployee?.employeeID,
        },
      })
    }, 500)
  }, [loggedInEmployee?.employeeID])
  console.log(IsSuperUserEmployee, 'gg.com.my')
  const navigations = [
    {
      name: 'Home',
      path: '/home',

      icon: IHome,
      // iconSelected: IHome,
      ACLIndex: 0,
      show: true,
    },
    {
      name: 'My Menu',
      path: '/MyMenu',
      icon: MyMenu,
      // iconSelected: IHome,
      ACLIndex: 0,
      show: !IsSuperUserEmployee
      ? false
      : GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
          HrPermission.HomeMymenuRead
        ),      
    },
    {
      name: 'My Subordinate',
      path: '/mysubordinates',
      icon: SubordinateSvg,
      iconSelected: ILogOutActive,
      ACLIndex: 20,
      show:
        !IsSuperUserEmployee 
          ? false
          : GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMysubordinateRead
          ),        
    },
    {
      name: 'System Admin',
      path: '/SystemAdmin/SystemAdminListing',
      icon: SystemAdmin,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeAdminExecSummaryRead
          )),
    },
    {
      name: 'Personnel Management',
      path: '/PersonnelManagementListing',
      icon: PersonnelManagement,
      iconSelected: IHomeActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomePersonnelExecSummaryRead
          )),
    },
    {
      name: 'Payroll Management',
      path: '/PayrollManagementListing',
      icon: PayrollManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        payroll &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomePayrollExecSummaryRead
          )),
    },
    {
      name: 'Leave Management',
      path: '/LeaveManagementListing',
      icon: LeaveManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myLeave &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeLeaveExecSummaryRead
          )),
    },
    {
      name: 'General Claim Management',
      path: '/ClaimManagementListing',
      icon: ClaimManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myClaim &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeClaimExecSummaryRead
          )),
    },
    {
      name: 'Project Expense Management',
      path: '/ProjectExpenseListing',
      icon: ProjectExpenseManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        projectExpense &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeProjectExecSummaryRead
          )),
    },
    {
      name: 'Requisition Management',
      path: '/RequisitionManagementListing',
      icon: RequisitionManagement,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myRequisition &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeRequisitionExecSummaryRead
          )),
    },
    {
      name: 'Time Attendance',
      path: '/TimeAttendanceManagementListing',
      icon: TimeAttendance,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myTMS &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeTmsExecSummaryRead
          )),
    },
    // {
    //   name: 'Health Care',
    //   path: '/TimeAttendanceManagementListing',
    //   icon: ILogOut,
    //   iconSelected: ILogOutActive,
    //   ACLIndex: 0,
    // },
    {
      name: 'Timesheet',
      path: '/TimesheetManagementListing',
      icon: Timesheet,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name !== 'Employee' &&
        myTimesheet &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeTimesheetRead
          )),
    },
    // {
    //   name: 'Performance Management',
    //   path: '/PerformanceManagementListing',
    //   icon: StaffAppraisal,
    //   iconSelected: ILogOutActive,
    //   ACLIndex: 0,
    //   show: true,
    //   // show: getRole[0]?.name != 'Employee' && myPerformance,
    // },
    // TRAINING, APPRAISAL & BI MODULES TO BE REMOVED FOR NOW
    // {
    //   name: 'Staff Training',
    //   path: '/TrainingManagementListing',
    //   icon: StaffTraining,
    //   iconSelected: ILogOutActive,
    //   ACLIndex: 0,
    //   show: getRole[0]?.name != 'Employee' && myTraining,
    // },

    // {
    // {
    //   name: 'Staff Development & Sucession',
    //   // path: '/RequisitionManagementListing',
    //   icon: ILogOut,
    //   iconSelected: ILogOutActive,
    //   ACLIndex: 0,
    // },
    // {
    //   name: 'Business Insight',
    //   path: '/BusinessInsightManagementListing',
    //   icon: BusinessInsight,
    //   iconSelected: ILogOutActive,
    //   ACLIndex: 0,
    //   show: getRole[0]?.name != 'Employee' && true,
    // },
    {
      name: 'Digital Reporting',
      path: '/DigitalReportingManagementListing',
      icon: DigitalReporting,
      iconSelected: ILogOutActive,
      ACLIndex: 0,
      show:
        getRole[0]?.name != 'Employee' &&
        (loggedInUserProfile?.superUser ||
          getRolePermissionByUser?.hasOwnProperty(
            HrPermission.HomeDigitalReportingView
          )),
    },
    {
      name: 'Logout',
      path: '/logout',
      icon: Logout,
      // iconSelected: ILogOut,
      ACLIndex: 0,
      show: true,
    },
  ]

  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('ACLIndex'))?.length === 0 &&
      !JSON.parse(localStorage.getItem('Employee'))?.EmployeeID
    ) {
      setInterval(() => {
        forceUpdate()
      }, 500)
    }
  }, [])

  const drawer =
    JSON.parse(localStorage.getItem('loggedInEmployee')) || loading ? (    
    <div className="route-container">
      <List disablePadding>
        {navigations
          ?.filter(x => x.show == true)
          ?.map((v, index) => (
            <div key={index}>
              <ListItem
                button
                dense
                // activeClassName={!IsSuperUserEmployee && 'active'}
                divider
                component={NavLink}
                to={v.path}
                onClick={onCloseDrawer}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v.name} />
              </ListItem>
            </div>
          ))}

        {/* {navigations.map((v, index) => (
          <div key={index}>
            {JSON.parse(localStorage.getItem('ACLIndex'))?.includes(
              v.ACLIndex
            ) || v.ACLIndex === 0 ? (
              <ListItem
                button
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={v.path}
                onClick={onCloseDrawer}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v.name} />
              </ListItem>
            ) : null}
          </div>
        ))} */}
      </List>
    </div>
  ) : (
    <Loading />
  )

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        {UserProfilePic?.UserProfilePic?.DocumentFile ? (
          <Avatar
            src={UserProfilePic?.UserProfilePic?.DocumentFile}
            className="avatar"
          />
        ) : (
          <Avatar alt="avatar" className="avatar" />
        )}
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">
                {loggedInUserProfile?.isEmployee
                  ? `${loggedInEmployee?.Salutation || ''} ${
                      loggedInEmployee?.name
                    }`
                  : `${loggedInUserProfile?.name}`}
              </span>
            }
            secondary={
              <span className="desc">{loggedInUserProfile?.email}</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="simple-menu"
              onClick={handleProfileClick}
            >
              <ExpandMore />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        disableScrollLock={true}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>

        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile/change-password')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile/change-password')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Authentication} alt="" />{' '}
          Change Password
        </MenuItem>

        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/logout')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/logout')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" /> Logout
        </MenuItem>
      </Menu>
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label="main menu">
      <Drawer
        {...rest}
        container={container}
        variant={variant}
        anchor={'left'}
        open={open}
        onClose={onCloseDrawer}
        className="drawer"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {userProfile}
        {drawer}
      </Drawer>
    </nav>
  )
}
