import React, { lazy } from 'react'

const EmployeeSalaryForm = lazy(() =>
  import('./EmployeeSalary/EmployeeSalaryForm').then(module => ({
    default: module.EmployeeSalaryForm,
  }))
)

const EmployeeSalaryListing = lazy(() =>
  import('./EmployeeSalary/EmployeeSalaryListing').then(module => ({
    default: module.EmployeeSalaryListing,
  }))
)

const MonthlyRemunerationForm = lazy(() =>
  import('./MonthlyRemuneration/MonthlyRemunerationForm').then(module => ({
    default: module.MonthlyRemunerationForm,
  }))
)

const BenefitInKindForm = lazy(() =>
  import('./BenefitsInKind/BenefitsInKindForm').then(module => ({
    default: module.BenefitInKindForm,
  }))
)

const TaxReliefListing = lazy(() =>
  import('./TaxRelief/TaxReliefListing').then(module => ({
    default: module.TaxReliefListing,
  }))
)

const TaxReliefForm = lazy(() =>
  import('./TaxRelief/TaxReliefForm').then(module => ({
    default: module.TaxReliefForm,
  }))
)

const FixedAllowanceNav = lazy(() =>
  import('./FixedAllowance/FixedAllowanceNav').then(module => ({
    default: module.FixedAllowanceNav,
  }))
)

const ManualEntryListing = lazy(() =>
  import('./FixedAllowance/ManualEntryListing').then(module => ({
    default: module.ManualEntryListing,
  }))
)

const ManualEntryForm = lazy(() =>
  import('./FixedAllowance/ManualEntryForm').then(module => ({
    default: module.ManualEntryForm,
  }))
)

const UploadListing = lazy(() =>
  import('./FixedAllowance/UploadListing').then(module => ({
    default: module.UploadListing,
  }))
)

const UploadValidationListing = lazy(() =>
  import('./FixedAllowance/UploadValidationListing').then(module => ({
    default: module.UploadValidationListing,
  }))
)

const MonthlyTransactionNav = lazy(() =>
  import('./MonthlyTransaction/MonthlyTransactionNav').then(module => ({
    default: module.MonthlyTransactionNav,
  }))
)

const MonthlyEmployeeListing = lazy(() =>
  import('./MonthlyTransaction/MonthlyEmployeeListing').then(module => ({
    default: module.MonthlyEmployeeListing,
  }))
)

const MonthlyEmployeeForm = lazy(() =>
  import('./MonthlyTransaction/MonthlyEmployeeForm').then(module => ({
    default: module.MonthlyEmployeeForm,
  }))
)

const MonthlyEmployeeView = lazy(() =>
  import('./MonthlyTransaction/MonthlyEmployeeView').then(module => ({
    default: module.MonthlyEmployeeView,
  }))
)

const MonthlyPayItemListing = lazy(() =>
  import('./MonthlyTransaction/MonthlyPayItemListing').then(module => ({
    default: module.MonthlyPayItemListing,
  }))
)

const MonthlyPayItemForm = lazy(() =>
  import('./MonthlyTransaction/MonthlyPayItemForm').then(module => ({
    default: module.MonthlyPayItemForm,
  }))
)

const MonthlyTransactionUploadListing = lazy(() =>
  import('./MonthlyTransaction/MonthlyTransactionUploadListing').then(
    module => ({
      default: module.MonthlyTransactionUploadListing,
    })
  )
)

const MonthlyTransactionUploadValidationListing = lazy(() =>
  import('./MonthlyTransaction/MonthlyTransactionUploadValidationListing').then(
    module => ({
      default: module.MonthlyTransactionUploadValidationListing,
    })
  )
)

const FreezePayrollList = lazy(() =>
  import('./FreezePayroll/FreezePayrollList').then(module => ({
    default: module.FrezePayrollList,
  }))
)

const PayrollInitiationList = lazy(() =>
  import('./PayrollInitiation/InitiationPeriod').then(module => ({
    default: module.InitiationPeriod,
  }))
)

const PayrollCycleListing = lazy(() =>
  import('./PayrollProcess/PayrollCycleListing').then(module => ({
    default: module.PayrollCycleListing,
  }))
)

const PayrollprocessSelection = lazy(() =>
  import('./PayrollProcess/PayrollprocessSelection').then(module => ({
    default: module.PayrollprocessSelection,
  }))
)

const PayrollProcessEmployeeSelection = lazy(() =>
  import('./PayrollProcess/PayrollProcessEmployeeSelection').then(module => ({
    default: module.PayrollProcessEmployeeSelection,
  }))
)

const ViewPayrollHistory = lazy(() =>
  import('./ViewPayrollHistory/ViewPayrollHistory').then(module => ({
    default: module.ViewPayrollHistory,
  }))
)

const EmployeePayrollCycle = lazy(() =>
  import('./ViewPayrollHistory/EmployeePayrollCycle').then(module => ({
    default: module.EmployeePayrollCycle,
  }))
)

const EmployeePayrollCycleDetails = lazy(() =>
  import('./ViewPayrollHistory/EmployeePayrollCycleDetails').then(module => ({
    default: module.EmployeePayrollCycleDetails,
  }))
)

const StatutoryAdjustmentPeriodListing = lazy(() =>
  import('./StatutoryAdjustment/StatutoryAdjustmentPeriodListing').then(module => ({
    default: module.StatutoryAdjustmentPeriodListing,
  }))
)

const StatutoryAdjustmentPayCycleListing = lazy(() =>
  import('./StatutoryAdjustment/StatutoryAdjustmentPayCycleListing').then(module => ({
    default: module.StatutoryAdjustmentPayCycleListing,
  }))
)

const StatutoryAdjustmentListing = lazy(() =>
  import('./StatutoryAdjustment/StatutoryAdjustmentListing').then(module => ({
    default: module.StatutoryAdjustmentListing,
  }))
)

const StatutoryAdjustmentForm = lazy(() =>
  import('./StatutoryAdjustment/StatutoryAdjustmentForm').then(module => ({
    default: module.StatutoryAdjustmentForm,
  }))
)

const PayrollClosing = lazy(() =>
  import('./PayrollClosing/PayrollClosing').then(module => ({
    default: module.PayrollClosing,
  }))
)

const PayrollClosingLocking = lazy(() =>
  import('./PayrollClosing/PayrollClosingLocking').then(module => ({
    default: module.PayrollClosingLocking,
  }))
)

const PublishPayslip = lazy(() =>
  import('./PublishPayslip/PublishPayslip').then(module => ({
    default: module.PublishPayslip,
  }))
)

const PublishEAFormListing = lazy(() =>
  import('./PublishEAForm/PublishEAFormListing').then(module => ({
    default: module.PublishEAFormListing,
  }))
)

const CP159ManagementListing = lazy(() =>
  import('./CP159Management/CP159ManagementListing').then(module => ({
    default: module.CP159ManagementListing,
  }))
)

const CP159ManagementForm = lazy(() =>
  import('./CP159Management/CP159ManagementForm').then(module => ({
    default: module.CP159ManagementForm,
  }))
)

const GLProcessingPayPeriodListing = lazy(() =>
  import('./GLProcessing/GLProcessingPayPeriodListing').then(module => ({
    default: module.GLProcessingPayPeriodListing,
  }))
)
const GLCycleListing = lazy(() =>
  import('./GLProcessing/GLCycleListing').then(module => ({
    default: module.GLCycleListing,
  }))
)

const GLProcessingListing = lazy(() =>
  import('./GLProcessing/GLProcessingListing').then(module => ({
    default: module.GLProcessingListing,
  }))
)

const GLProcessingDetailsView = lazy(() =>
  import('./GLProcessing/GLProcessingDetailsView').then(module => ({
    default: module.GLProcessingDetailsView,
  }))
)

const GLPayPeriodListing = lazy(() =>
  import('./GLExport/GLPayPeriodListing').then(module => ({
    default: module.GLPayPeriodListing,
  }))
)

const GLListing = lazy(() =>
  import('./GLExport/GLListing').then(module => ({
    default: module.GLListing,
  }))
)

const GLDetailsView = lazy(() =>
  import('./GLExport/GLDetailsView').then(module => ({
    default: module.GLDetailsView,
  }))
)

const CompanySettingPayItemListing = lazy(() =>
  import('./CompanySetting/PayItem/PayItemListing').then(module => ({
    default: module.PayItemListing,
  }))
)

const CompanySettingPayrollCycleListing = lazy(() =>
  import('./CompanySetting/PayrollCycle/PayrollCycleListing').then(module => ({
    default: module.PayrollCycleListing,
  }))
)

const PayrollCalanderMonthListing = lazy(() =>
  import('./CompanySetting/PayrollCalander/PayrollCalanderMonthListing').then(
    module => ({
      default: module.PayrollCalanderMonthListing,
    })
  )
)

const PayrollCalanderEditForm = lazy(() =>
  import('./CompanySetting/PayrollCalander/PayrollCalanderEditForm').then(
    module => ({
      default: module.PayrollCalanderEditForm,
    })
  )
)

const PayrollManagementRoutes = [
  {
    props: { exact: true, path: '/EmployeeSalary/EmployeeSalaryListing' },
    component: <EmployeeSalaryListing />,
  },
  {
    props: { exact: true, path: '/EmployeeSalary/EmployeeSalaryForm/Add' },
    component: <EmployeeSalaryForm mode="add" />,
  },
  {
    props: { exact: true, path: '/EmployeeSalary/EmployeeSalaryForm/Edit' },
    component: <EmployeeSalaryForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/MonthlyRemuneration/MonthlyRemunerationForm',
    },
    component: <MonthlyRemunerationForm />,
  },
  {
    props: { exact: true, path: '/BenefitsInKind/BenefitsInKindForm' },
    component: <BenefitInKindForm />,
  },
  {
    props: { exact: true, path: '/TaxRelief/TaxReliefListing' },
    component: <TaxReliefListing />,
  },
  {
    props: { exact: true, path: '/TaxRelief/TaxReliefForm' },
    component: <TaxReliefForm />,
  },
  {
    props: { exact: true, path: '/FixedAllowance/FixedAllowanceNav' },
    component: <FixedAllowanceNav />,
  },
  {
    props: { exact: true, path: '/PayrollInitiation/PayrollInitiationListing' },
    component: <PayrollInitiationList />,
  },
  {
    props: { exact: true, path: '/FixedAllowance/ManualEntryListing' },
    component: <ManualEntryListing />,
  },
  {
    props: { exact: true, path: '/FixedAllowance/ManualEntryForm' },
    component: <ManualEntryForm />,
  },
  {
    props: { exact: true, path: '/FixedAllowance/UploadListing' },
    component: <UploadListing />,
  },
  {
    props: { exact: true, path: '/FixedAllowance/UploadValidationListing' },
    component: <UploadValidationListing />,
  },
  {
    props: { exact: true, path: '/MonthlyTransaction/MonthlyTransactionNav' },
    component: <MonthlyTransactionNav />,
  },
  {
    props: { exact: true, path: '/MonthlyTransaction/MonthlyEmployeeListing' },
    component: <MonthlyEmployeeListing />,
  },
  {
    props: { exact: true, path: '/MonthlyTransaction/MonthlyEmployeeForm/Add' },
    component: <MonthlyEmployeeForm />,
  },
  {
    props: {
      exact: true,
      path: '/MonthlyTransaction/MonthlyEmployeeForm/Edit',
    },
    component: <MonthlyEmployeeForm />,
  },
  {
    props: {
      exact: true,
      path: '/MonthlyTransaction/MonthlyEmployeeView',
    },
    component: <MonthlyEmployeeView />,
  },
  {
    props: { exact: true, path: '/MonthlyTransaction/MonthlyPayItemListing' },
    component: <MonthlyPayItemListing />,
  },
  {
    props: { exact: true, path: '/MonthlyTransaction/MonthlyPayItemForm' },
    component: <MonthlyPayItemForm />,
  },
  {
    props: {
      exact: true,
      path: '/MonthlyTransaction/MonthlyTransactionUploadListing',
    },
    component: <MonthlyTransactionUploadListing />,
  },
  {
    props: {
      exact: true,
      path: '/MonthlyTransaction/MonthlyTransactionUploadValidationListing',
    },
    component: <MonthlyTransactionUploadValidationListing />,
  },
  {
    props: { exact: true, path: '/FreezePayroll/FreezePayrollList' },
    component: <FreezePayrollList />,
  },
  {
    props: { exact: true, path: '/PayrollProcess/PayrollCycleListing' },
    component: <PayrollCycleListing />,
  },
  {
    props: { exact: true, path: '/PayrollProcess/PayrollprocessSelection' },
    component: <PayrollprocessSelection />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollProcess/PayrollProcessEmployeeSelection',
    },
    component: <PayrollProcessEmployeeSelection />,
  },
  {
    props: { exact: true, path: '/ViewPayrollHistory/ViewPayrollHistory' },
    component: <ViewPayrollHistory />,
  },
  {
    props: { exact: true, path: '/ViewPayrollHistory/EmployeePayrollCycle' },
    component: <EmployeePayrollCycle />,
  },
  {
    props: {
      exact: true,
      path: '/ViewPayrollHistory/EmployeePayrollCycleDetails',
    },
    component: <EmployeePayrollCycleDetails />,
  },
  {
    props: { exact: true, path: '/StatutoryAdjustment' },
    component: <StatutoryAdjustmentPeriodListing />,
  },
  {
    props: { exact: true, path: '/StatutoryAdjustment/PayCycleListing' },
    component: <StatutoryAdjustmentPayCycleListing />,
  },
  {
    props: { exact: true, path: '/StatutoryAdjustment/StatutoryAdjustmentListing' },
    component: <StatutoryAdjustmentListing />,
  },
  {
    props: { exact: true, path: '/StatutoryAdjustment/StatutoryAdjustmentListing/StatutoryAdjustmentForm' },
    component: <StatutoryAdjustmentForm />,
  },
  {
    props: { exact: true, path: '/PayrollClosing/PayrollClosing' },
    component: <PayrollClosing />,
  },
  {
    props: { exact: true, path: '/PayrollClosing/PayrollClosingLocking' },
    component: <PayrollClosingLocking />,
  },
  {
    props: { exact: true, path: '/PublishPayslip/PublishPayslip' },
    component: <PublishPayslip />,
  },
  {
    props: { exact: true, path: '/PublishEAForm/PublishEAFormListing' },
    component: <PublishEAFormListing />,
  },
  {
    props: { exact: true, path: '/CP159Management/CP159ManagementListing' },
    component: <CP159ManagementListing />,
  },
  {
    props: { exact: true, path: '/CP159Management/CP159ManagementForm' },
    component: <CP159ManagementForm />,
  },

  {
    props: { exact: true, path: '/GLProcess/GLProcessingPayPeriodListing' },
    component: <GLProcessingPayPeriodListing />,
  },
  {
    props: { exact: true, path: '/GLExport/GLPayPeriodListing' },
    component: <GLPayPeriodListing />,
  },

  {
    props: {
      exact: true,
      path: '/GLProcess/GLPayPeriodListing/GLCycleListing',
    },
    component: <GLCycleListing />,
  },
  {
    props: {
      exact: true,
      path: '/GLProcess/GLPayPeriodListing/GLCycleListing/GLProcessingListing',
    },
    component: <GLProcessingListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/GLProcess/GLPayPeriodListing/GLCycleListing/GLProcessingListing/GLDetails',
    },
    component: <GLProcessingDetailsView />,
  },
  {
    props: { exact: true, path: '/GLExport/GLPayPeriodListing/GLListing' },
    component: <GLListing />,
  },

  {
    props: { exact: true, path: '/GLExport/GLPayPeriodListing/view' },
    component: <GLDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayItemListing',
    },
    component: <CompanySettingPayItemListing />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayrollCycleListing',
    },
    component: <CompanySettingPayrollCycleListing />,
  },

  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayrollCalendar',
    },
    component: <PayrollCalanderMonthListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/PayrollCalendar/PayrollCalanderEditForm',
    },
    component: <PayrollCalanderEditForm />,
  },
]

export default PayrollManagementRoutes
