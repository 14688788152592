import React, { lazy } from 'react'

const Gender = lazy(() => 
  import('./Personnel/Gender/Gender').then(module => ({
    default: module.Gender
  }))
)

const Race = lazy(() => 
  import('./Personnel/Race/Race').then(module => ({
    default: module.Race
  }))
)

const Religion = lazy(() => 
  import('./Personnel/Religion/Religion').then(module => ({
    default: module.Religion
  }))
)

const MaritalStatus = lazy(() => 
  import('./Personnel/MaritalStatus/MaritalStatus').then(module => ({
    default: module.MaritalStatus
  }))
)

const AgeGroup = lazy(() => 
  import('./Personnel/AgeGroup/AgeGroup').then(module => ({
    default: module.AgeGroup
  }))
)

const EmploymentClassification = lazy(() => 
  import('./Personnel/EmploymentClassification/EmploymentClassification').then(module => ({
    default: module.EmploymentClassification
  }))
)

const JobGrade = lazy(() => 
  import('./Personnel/JobGrade/JobGrade').then(module => ({
    default: module.JobGrade
  }))
)

const YearsofService = lazy(() => 
  import('./Personnel/YearsOfService/YearsofService').then(module => ({
    default: module.YearsofService
  }))
)

const RecruitmentSource = lazy(() => 
  import('./Personnel/RecruitmentSource/RecruitmentSource').then(module => ({
    default: module.RecruitmentSource
  }))
)

const StaffTurnOver = lazy(() => 
  import('./Personnel/StaffTurnOver/StaffTurnOver').then(module => ({
    default: module.StaffTurnOver
  }))
)

const BusinessInsightRoutes = [
  {
    props: { exact: true, path: '/Personnel/Gender/Gender' },
    component: <Gender />,
  },
  {
    props: { exact: true, path: '/Personnel/Race/Race' },
    component: <Race />,
  },
  {
    props: { exact: true, path: '/Personnel/Religion/Religion' },
    component: <Religion />,
  },
  {
    props: { exact: true, path: '/Personnel/MaritalStatus/MaritalStatus' },
    component: <MaritalStatus />,
  },
  {
    props: { exact: true, path: '/Personnel/AgeGroup/AgeGroup' },
    component: <AgeGroup />,
  },
  {
    props: { exact: true, path: '/Personnel/EmploymentClassification/EmploymentClassification' },
    component: <EmploymentClassification />,
  },
  {
    props: { exact: true, path: '/Personnel/JobGrade/JobGrade' },
    component: <JobGrade />,
  },
  {
    props: { exact: true, path: '/Personnel/YearsOfService/YearsofService' },
    component: <YearsofService />,
  },
  {
    props: { exact: true, path: '/Personnel/RecruitmentSource/RecruitmentSource' },
    component: <RecruitmentSource />,
  },
  {
    props: { exact: true, path: '/Personnel/StaffTurnOver/StaffTurnOver' },
    component: <StaffTurnOver />,
  },
]

export default BusinessInsightRoutes