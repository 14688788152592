import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  InputBase,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
} from '@material-ui/core'
import React, { useState, MouseEvent } from 'react'
import { Close, Search, SyncAlt } from '@material-ui/icons'
import theme from '@ifca-root/react-component/src/assets/theme'
interface ISearchHeaderProps {
  title: string
  value?: string
  onClick?: any
  input?: any
  searchtext?: string
  issearch?: boolean
  disableScrollLock?: boolean
  option?: {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    icon?: any
  }
  search?: boolean
  onChangeAction?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCloseAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
  sort?: boolean
  sortValue?: any
  sortArr?: string[]
  sortChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  dynamicInfo?: boolean
  dynamicSubheader?: boolean
  dynamicSubHeaderFull?: boolean
  multiDynamicInfo?: boolean
  multiDynamicSubInfo?: boolean
  dynamicYearTabs?: boolean
  fixed?: boolean
  fixed2?: boolean
  tabs?: boolean
  searchHeaderMargin?: boolean
  half?: boolean
  defaultValue?: string
  dynamicTabsSearch?: boolean
  tabsSearch?: boolean
  titleDynamic?: boolean
  multiDynamicSearch?: boolean
  dynamicYearTabsSearch?: boolean
  tabsTop?: boolean
  CompanyTitleSearch?: boolean
  titleNamePosition?: boolean
  MonthSalary?: boolean
  bulletinSearch?: boolean
  dynamicDateRangeSearch?: boolean
  tabReportingFormSearch?: boolean
}
export const SearchHeader = (props: ISearchHeaderProps) => {
  const {
    title,
    value,
    search,
    option,
    fixed2,
    sort,
    sortValue,
    sortArr,
    sortChange,
    input,
    searchtext,
    issearch,
    titleNamePosition,
    onChangeAction,
    onCloseAction,
    onKeyPress,
    disableScrollLock,
    dynamicInfo,
    dynamicSubheader,
    MonthSalary,
    dynamicSubHeaderFull,
    dynamicYearTabs,
    multiDynamicInfo,
    multiDynamicSubInfo,
    fixed,
    tabs,
    searchHeaderMargin,
    CompanyTitleSearch,
    half,
    defaultValue,
    dynamicTabsSearch,
    tabsSearch,
    titleDynamic,
    multiDynamicSearch,
    dynamicYearTabsSearch,
    tabsTop,
    bulletinSearch,
    dynamicDateRangeSearch,
    tabReportingFormSearch,
  } = props

  // const [searchBar, setSearch] = useState(false)

  // if (issearch === true) {
  //   setSearch(issearch ? issearch : false)
  // } else {
  //   setSearch(input == null || input ==  '' || input == undefined ? false : true)
  // }

  const [searchBar, setSearch] = useState(
    issearch
      ? issearch
      : input == null || input == '' || input == undefined
      ? false
      : true
  )

  // // const [searchBar, setSearch] = useState(false)
  // const [searchBar, setSearch] = useState(
  //   input == null || input ==  '' || input == undefined ? false : true
  // )

  const [Empty, setEmpty] = useState(false)
  const handleSearchToggle = () => {
    setSearch(!searchBar)
  }

  const useMenuOption = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    return {
      anchorEl,
      handleClick,
      handleClose,
    }
  }

  const { anchorEl, handleClick, handleClose } = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const ITEM_HEIGHT = 48

  return (
    <List
      id="search-header"
      className={`search-header 
      ${fixed && 'fixed-search-header'}
      ${fixed2 && 'fixed-search-header2'}
      ${MonthSalary && 'fixed-search-header with-month-salary'}
      ${titleNamePosition && 'fixed-search-header with-title-name-position'}
      ${tabs && 'fixed-search-header with-tabs'}
      ${disableScrollLock && 'fixed-search-header with-disable-scroll-lock'}
      ${CompanyTitleSearch && 'fixed-search-header with-company-title-search'}
      ${half && 'search-header-booking'}
      ${tabsTop && ' fixed-search-header with-mobile-top'}
      ${dynamicInfo && ' fixed-search-header with-dynamic'}
      ${dynamicSubheader && ' fixed-search-header with-dynamic-subheader'}
      ${dynamicSubHeaderFull &&
        ' fixed-search-header with-dynamic-subheader-full'}
      ${multiDynamicInfo && 'fixed-search-header with-dynamic-multiInfo'}
      ${searchHeaderMargin && 'fixed-search-header with-dynamic-subheader-full'}
      ${multiDynamicSubInfo && 'fixed-search-header with-dynamic-multiSubInfo'}
      ${dynamicYearTabs && 'fixed-search-header with-dynamic-year-tabs'}
      ${dynamicTabsSearch && 'fixed-search-header with-dynamic-tabs-search'}
      ${tabsSearch && 'fixed-search-header with-tabs-search'}
      ${bulletinSearch && 'fixed-search-header bulletin-search'}
      ${titleDynamic && 'fixed-search-header with-title-dynamic'}
      ${multiDynamicSearch && 'fixed-search-header with-dynamic-search'}
      ${dynamicYearTabsSearch &&
        'fixed-search-header with-dynamic-year-tabs-search'}
      ${dynamicDateRangeSearch &&
        'fixed-search-header with-dynamic-daterange-search'}
      ${tabReportingFormSearch && 'fixed-search-header with-tab-reporting-form'}
      `}
    >
      <ListItem>
        <ListItemText
          disableTypography
          primary={
            <>
              <span className="xsTitle flex-space">
                {title}{' '}
                {value && (
                  <>
                    (<span className="highlight-text">{value}</span>)
                  </>
                )}
              </span>
            </>
          }
        />
        <ListItemSecondaryAction>
          {search ? (
            <IconButton
              edge="end"
              aria-label="search"
              // onClick={el.onClick}
              onClick={handleSearchToggle}
            >
              <Search />
            </IconButton>
          ) : null}
          {option && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={option?.onClick}
            >
              {option?.icon}
            </IconButton>
          )}
          {sort ? (
            <IconButton
              edge="end"
              aria-label="delete"
              aria-controls="menu-list"
              onClick={e => handleClick(e)}
            >
              <SyncAlt
                style={{ transform: 'rotate(90deg)' }}
                fontSize="small"
              />
            </IconButton>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <div
        style={{
          height: searchBar ? '4px' : '0',
          marginTop: searchBar ? '4px' : '0',
          transition: '.2s ease',
          transitionProperty: 'height',
        }}
      ></div>
      <ListItem className={`search-field ${searchBar ? null : 'hide'}`}>
        <ListItemText
          disableTypography
          primary={
            <>
              <InputBase
                defaultValue={defaultValue || searchtext || null || input}
                placeholder="Search here.."
                // defaultValue={searchtext}
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChangeAction}
                onKeyPress={onKeyPress}
                // defaultValue={input}
                value={!searchBar ? '' : null}
                // inputRef={input => {
                //   input && searchBar && input.focus({ value: '' })
                //   // input && searchBar && input.focus()
                //   // input && Empty && (input.value = '')
                // }}
                inputRef={input => {
                  input && searchBar && input.focus()
                  if (Empty) {
                    input && Empty && (input.value = '')
                    setEmpty(false)
                  }
                }}
              />
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            // edge="end"
            // aria-label="close"
            onClick={e => {
              handleSearchToggle()
              onCloseAction(e)
            }}
            className={`close-search ${searchBar ? null : 'hide'}`}
          >
            <Close />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        disableScrollLock={true}
        id="menu-list"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        // style={{top: '80px'}}
        style={{
          marginTop: isDesktop ? '100px' : '80px',
          left: isDesktop ? '-40px' : '0px',
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;Sort By
        <RadioGroup aria-label="example" value={sortValue}>
          {sortArr
            ? sortArr?.map(el => (
                <MenuItem key={el} value={el} className="drawer-dropdown">
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        size="small"
                        style={{ paddingLeft: '12px' }}
                        onClick={(e: any) => sortChange(e)}
                      />
                    }
                    label={el}
                    value={el}
                  />
                </MenuItem>
              ))
            : null}
        </RadioGroup>
      </Menu>
    </List>
  )
}
