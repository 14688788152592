import React, { lazy } from 'react'

const EmployeeTimesheet = lazy(() =>
  import('./EmployeeTimesheet/EmployeeTimesheet').then(module => ({
    default: module.EmployeeTimesheet,
  }))
)

const ManDayAccountCodeForm = lazy(() =>
  import('./ManDayAccountCode/ManDayAccountCodeForm').then(module => ({
    default: module.ManDayAccountCodeForm,
  }))
)

const ManDayAccountCodeDetail = lazy(() =>
  import('./ManDayAccountCode/ManDayAccountCodeDetail').then(module => ({
    default: module.ManDayAccountCodeDetail,
  }))
)

const ManDayAccountTypeForm = lazy(() =>
  import('./ManDayAccountType/ManDayAccountTypeForm').then(module => ({
    default: module.ManDayAccountTypeForm,
  }))
)

const ManDayAccountTypeDetail = lazy(() =>
  import('./ManDayAccountType/ManDayAcountTypeDetail').then(module => ({
    default: module.ManDayAccountTypeDetail,
  }))
)


const CompanySettings = lazy(() =>
  import('./CompanySettings/MenuListings/MenuListing').then(module => ({
    default: module.MenuListing,
  }))
)

const TsAccountCodeListing = lazy(() =>
  import('./CompanySettings/MenuListings/TsAccountCode/TsAccountCodeListing').then(module => ({
    default: module.TsAccountCodeListing,
  }))
)

const TsAccountTypeForCodeListing = lazy(() =>
  import('./CompanySettings/MenuListings/TsAccountCode/TsAccountTypeForCodeListing').then(module => ({
    default: module.TsAccountTypeForCodeListing,
  }))
)

const TsAccountTypeListing = lazy(() =>
  import('./CompanySettings/MenuListings/TsAccountType/TsAccountTypeListing').then(module => ({
    default: module.TsAccountTypeListing,
  }))
)

const TsAccountTypeForm = lazy(() =>
  import('./CompanySettings/MenuListings/TsAccountType/TsAccountTypeForm').then(module => ({
    default: module.TsAccountTypeForm,
  }))
)

const TimesheetManagementRoutes = [
  {
    props: { exact: true, path: '/EmployeeTimesheet/EmployeeTimesheet' },
    component: <EmployeeTimesheet />,
  },
  {
    props: { exact: true, path: '/ManDayAccountCode/ManDayAccountCodeForm' },
    component: <ManDayAccountCodeForm />,
  },
  {
    props: { exact: true, path: '/ManDayAccountCode/ManDayAccountCodeDetail' },
    component: <ManDayAccountCodeDetail />,
  },
  {
    props: { exact: true, path: '/ManDayAccountType/ManDayAccountTypeForm' },
    component: <ManDayAccountTypeForm />,
  },
  {
    props: { exact: true, path: '/ManDayAccountType/ManDayAcountTypeDetail' },
    component: <ManDayAccountTypeDetail />,
  },


  {
    props: { exact: true, path: '/TimesheetManagement/CompanySettings' },
    component: <CompanySettings />,
  },
  {
    props: { exact: true, path: '/TimesheetManagement/CompanySettings/TsAccountCodeListing' },
    component: <TsAccountCodeListing />,
  },
  {
    props: { exact: true, path: '/TimesheetManagement/CompanySettings/TsAccountTypeForCodeListing' },
    component: <TsAccountTypeForCodeListing />,
  },
  {
    props: { exact: true, path: '/TimesheetManagement/CompanySettings/TsAccountTypeListing' },
    component: <TsAccountTypeListing />,
  },
  {
    props: { exact: true, path: '/TimesheetManagement/CompanySettings/TsAccountTypeForm' },
    component: <TsAccountTypeForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimesheetManagement/CompanySettings/TsAccountTypeForm/add',
    },
    component: <TsAccountTypeForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimesheetManagement/CompanySettings/TsAccountTypeForm/edit',
    },
    component: <TsAccountTypeForm mode="edit" />,
  },
]

export default TimesheetManagementRoutes