import React, { lazy } from 'react'

const TrainingCostBudgetListing = lazy(() =>
  import('../TrainingModule/TrainingCostBudget/TrainingCostBudgetListing').then(
    module => ({
      default: module.TrainingCostBudgetListing,
    })
  )
)
const ScheduledTrainingCoursesListing = lazy(() =>
  import(
    '../TrainingModule/ScheduledTrainingCourses/ScheduledTrainingCoursesListing'
  ).then(module => ({
    default: module.ScheduledTrainingCoursesListing,
  }))
)
const ScheduledTrainingCoursesForm = lazy(() =>
  import(
    '../TrainingModule/ScheduledTrainingCourses/ScheduledTrainingCoursesForm'
  ).then(module => ({
    default: module.ScheduledTrainingCoursesForm,
  }))
)
const TrainingSchedule = lazy(() =>
  import('../TrainingModule/ScheduledTrainingCourses/TrainingSchedule').then(
    module => ({
      default: module.TrainingSchedule,
    })
  )
)
const TraineeRegistration = lazy(() =>
  import('../TrainingModule/ScheduledTrainingCourses/TraineeRegistration').then(
    module => ({
      default: module.TraineeRegistration,
    })
  )
)
const QRCode = lazy(() =>
  import('../TrainingModule/ScheduledTrainingCourses/QRCode').then(
    module => ({
      default: module.QRCode,
    })
  )
)
const TraineeScheduleCoursesListing = lazy(() =>
  import(
    '../TrainingModule/TraineeEvaluation/TraineeScheduleCoursesListing'
  ).then(module => ({
    default: module.TraineeScheduleCoursesListing,
  }))
)
const TraineeListing = lazy(() =>
  import('../TrainingModule/TraineeEvaluation/TraineeListing').then(module => ({
    default: module.TraineeListing,
  }))
)
const CourseScheduleListing = lazy(() =>
  import('../TrainingModule/CourseEvaluation/CourseScheduleListing').then(
    module => ({
      default: module.CourseScheduleListing,
    })
  )
)
const SupervisorScheduleCoursesListing = lazy(() =>
  import(
    '../TrainingModule/SupervisorEvaluation/SupervisorScheduleCoursesListing'
  ).then(module => ({
    default: module.SupervisorScheduleCoursesListing,
  }))
)
const CourseTraineeListing = lazy(() =>
  import('../TrainingModule/CourseEvaluation/CourseTraineeListing').then(
    module => ({
      default: module.CourseTraineeListing,
    })
  )
)
const TrainerTraineeProviderListing = lazy(() =>
  import(
    '../TrainingModule/TrainerEvaluation/TrainerTraineeProviderListing'
  ).then(module => ({
    default: module.TrainerTraineeProviderListing,
  }))
)
const TrainerCourseTitleListing = lazy(() =>
  import('../TrainingModule/TrainerEvaluation/TrainerCourseTitleListing').then(
    module => ({
      default: module.TrainerCourseTitleListing,
    })
  )
)
const TrainerTraineeListing = lazy(() =>
  import('./TrainerEvaluation/TrainerTraineeListing').then(module => ({
    default: module.TrainerTraineeListing,
  }))
)
const SupervisorTraineeListing = lazy(() =>
  import(
    '../TrainingModule/SupervisorEvaluation/SupervisorTraineeListing'
  ).then(module => ({
    default: module.SupervisorTraineeListing,
  }))
)
const TrainingCostListing = lazy(() =>
  import('../TrainingModule/TrainingCost/TrainingCostListing').then(module => ({
    default: module.TrainingCostListing,
  }))
)
const HRDFClaimListing = lazy(() =>
  import('../TrainingModule/HRDF Claim/HRDFClaimListing').then(module => ({
    default: module.HRDFClaimListing,
  }))
)
const TrainingCostForm = lazy(() =>
  import('../TrainingModule/TrainingCost/TrainingCostForm').then(module => ({
    default: module.TrainingCostForm,
  }))
)
const TrainingCostAllocationListing = lazy(() =>
  import(
    '../TrainingModule/TrainingCostAllocation/TrainingCostAllocationListing'
  ).then(module => ({
    default: module.TrainingCostAllocationListing,
  }))
)
const TrainingCostAllocationForm = lazy(() =>
  import(
    '../TrainingModule/TrainingCostAllocation/TrainingCostAllocationForm'
  ).then(module => ({
    default: module.TrainingCostAllocationForm,
  }))
)
const TrainingJobGradeListing = lazy(() =>
  import('../TrainingModule/Training Job Grade/TrainingJobGradeListing').then(
    module => ({
      default: module.TrainingJobGradeListing,
    })
  )
)
const TrainingJobGradeEmployeeListing = lazy(() =>
  import('./Training Job Grade/TrainingJobGradeEmployeeListing').then(
    module => ({
      default: module.TrainingJobGradeEmployeeListing,
    })
  )
)

const TrainingCoursesListing = lazy(() =>
  import('../TrainingModule/Training Courses/TrainingCoursesListing').then(
    module => ({
      default: module.TrainingCoursesListing,
    })
  )
)
const TrainingCoursesEmployeeListing = lazy(() =>
  import('./Training Courses/TrainingCoursesEmployeeListing').then(module => ({
    default: module.TrainingCoursesEmployeeListing,
  }))
)
const EmployeeRecordsListing = lazy(() =>
  import('./Employee Training Records/EmployeeRecordsListing').then(module => ({
    default: module.EmployeeRecordsListing,
  }))
)
const TrainingAttendanceListing = lazy(() =>
  import('../TrainingModule/TrainingAttendance/TrainingAttendanceListing').then(
    module => ({
      default: module.TrainingAttendanceListing,
    })
  )
)
const TrainingAttendanceDetailListing = lazy(() =>
  import(
    '../TrainingModule/TrainingAttendance/TrainingAttendanceDetailListing'
  ).then(module => ({
    default: module.TrainingAttendanceDetailListing,
  }))
)
const EmployeeRecordDetailListing = lazy(() =>
  import('./Employee Training Records/EmployeeRecordDetailListing').then(
    module => ({
      default: module.EmployeeRecordDetailListing,
    })
  )
)

const trainingRoutes = [
  {
    props: { path: '/home/TrainingCostBudget/TrainingCostBudgetListing' },
    component: <TrainingCostBudgetListing />,
  },
  {
    props: {
      path: '/home/ScheduledTrainingCourses/ScheduledTrainingCoursesListing',
    },
    component: <ScheduledTrainingCoursesListing />,
  },
  {
    props: {
      path: '/home/ScheduledTrainingCourses/ScheduledTrainingCoursesForm/add',
    },
    component: <ScheduledTrainingCoursesForm mode="add" />,
  },
  {
    props: {
      path: '/home/ScheduledTrainingCourses/ScheduledTrainingCoursesForm/edit',
    },
    component: <ScheduledTrainingCoursesForm mode="edit" />,
  },
  {
    props: {
      path: '/home/ScheduledTrainingCourses/ScheduledTrainingCoursesForm/reopen',
    },
    component: <ScheduledTrainingCoursesForm mode="reopen" />,
  },
  {
    props: { path: '/home/ScheduledTrainingCourses/TrainingSchedule/add' },
    component: <TrainingSchedule mode="add" />,
  },
  {
    props: { path: '/home/ScheduledTrainingCourses/TrainingSchedule/edit' },
    component: <TrainingSchedule mode="edit" />,
  },
  {
    props: { path: '/home/ScheduledTrainingCourses/TraineeRegistration' },
    component: <TraineeRegistration/>,
  },
  {
    props: { path: '/home/ScheduledTrainingCourses/QRCOde' },
    component: <QRCode/>,
  },
  {
    props: { path: '/home/TraineeEvaluation/TraineeScheduleCoursesListing' },
    component: <TraineeScheduleCoursesListing />,
  },
  {
    props: { path: '/home/CourseEvaluation/CourseScheduleListing' },
    component: <CourseScheduleListing />,
  },
  {
    props: { path: '/home/TrainerEvaluation/TrainerTraineeProviderListing' },
    component: <TrainerTraineeProviderListing />,
  },
  {
    props: { path: '/home/TrainerEvaluation/TrainerCourseTitleListing' },
    component: <TrainerCourseTitleListing />,
  },
  {
    props: { path: '/home/TrainerEvaluation/TrainerTraineeListing' },
    component: <TrainerTraineeListing />,
  },
  {
    props: {
      path: '/home/SupervisorEvaluation/SupervisorScheduleCoursesListing',
    },
    component: <SupervisorScheduleCoursesListing />,
  },
  {
    props: { path: '/home/TraineeEvaluation/TraineeListing' },
    component: <TraineeListing />,
  },
  {
    props: { path: '/home/CourseEvaluation/CourseTraineeListing' },
    component: <CourseTraineeListing />,
  },
  {
    props: { path: '/home/SupervisorEvaluation/SupervisorTraineeListing' },
    component: <SupervisorTraineeListing />,
  },
  {
    props: { path: '/home/TrainingCost/TrainingCostListing' },
    component: <TrainingCostListing />,
  },
  {
    props: { path: '/home/HRDF Claim/HRDFClaimListing' },
    component: <HRDFClaimListing />,
  },
  {
    props: { path: '/home/TrainingCost/TrainingCostForm' },
    component: <TrainingCostForm />,
  },
  {
    props: {
      path: '/home/TrainingCostAllocation/TrainingCostAllocationListing',
    },
    component: <TrainingCostAllocationListing />,
  },
  {
    props: { path: '/home/TrainingCostAllocation/TrainingCostAllocationForm' },
    component: <TrainingCostAllocationForm />,
  },
  {
    props: { path: '/home/TrainingJobGrade/TrainingJobGradeListing' },
    component: <TrainingJobGradeListing />,
  },
  {
    props: { path: '/home/TrainingCourses/TrainingCoursesListing' },
    component: <TrainingCoursesListing />,
  },
  {
    props: { path: '/home/TrainingCourses/TrainingCoursesEmployeeListing' },
    component: <TrainingCoursesEmployeeListing />,
  },
  {
    props: { path: '/home/TrainingCourses/TrainingJobGradeEmployeeListing' },
    component: <TrainingJobGradeEmployeeListing />,
  },
  {
    props: { path: '/home/TrainingCourses/EmployeeRecordsListing' },
    component: <EmployeeRecordsListing />,
  },
  {
    props: { path: '/home/TrainingCourses/EmployeeRecordDetailListing' },
    component: <EmployeeRecordDetailListing />,
  },
  {
    props: { path: '/home/TrainingAttendance/TrainingAttendanceListing' },
    component: <TrainingAttendanceListing />,
  },
  {
    props: { path: '/home/TrainingAttendance/TrainingAttendanceDetailListing' },
    component: <TrainingAttendanceDetailListing />,
  },
]

export default trainingRoutes
