// import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes'
import authenticationRoutes from 'containers/AccountModule/AccountRoutes'
import dashboardRoutes from 'containers/DashboardModule/DashboardRoutes'
import React from 'react'
import { Redirect } from 'react-router-dom'
import logOutRoutes from 'containers/LogOutModule/LogOutRoutes'
import HomeRoutes from 'containers/HomeModule/HomeRoutes'
import PersonnelRoutes from 'containers/HomeModule/PersonnelModule/PersonnelRoutes'
import PayrollManagementRoutes from 'containers/HomeModule/PayrollManagement/PayrollManagementRoutes'
import LeaveManagementRoutes from 'containers/HomeModule/LeaveManagement/LeaveManagementRoutes'
import TimeAttendanceManagementRoutes from 'containers/HomeModule/TimeAttendanceManagement/TimeAttendanceManagementRoutes'
import RequisitionManagementRoutes from 'containers/HomeModule/RequisitionManagement/RequisitionManagementRoutes'
import PersonnelEFileRoutes from 'containers/HomeModule/PersonnelModule/PersonnelEFile/PersonnelEFileRoutes'
import claimRoutes from 'containers/HomeModule/ClaimModule/ClaimRoutes'
import PersonnelEmployeeRoutes from 'containers/HomeModule/PersonnelModule/EmploymentInfo/PersonnelEmployeeRoutes'
import BusinessInsightRoutes from 'containers/HomeModule/BusinessInsightManagement/BusinessInsightRoutes'
import TimesheetManagementRoutes from 'containers/HomeModule/TimesheetManagement/TimesheetManagementRoutes'
import StaffAppraisalManagementRoutes from 'containers/HomeModule/StaffAppraisalManagement/StaffAppraisalManagementRoutes'
import trainingRoutes from 'containers/HomeModule/TrainingModule/TrainingRoutes'
import DigitalReportingManagementRoutes from 'containers/HomeModule/DigitalReportingManagement/DigitalReportingManagementRoutes'
import CompanySummaryRoutes from 'containers/HomeModule/SystemAdmin/Company/CompanySummaryRoutes'
import DigitalDocumentRoutes from 'containers/HomeModule/PersonnelModule/EmploymentInfo/EmployeeDigitalRecords/DigitalDocumentRoutes'
import ProjectExpenseRoutes from 'containers/HomeModule/ProjectExpenseModule/ProjectExpenseRoutes'
import ShopeeRoutes from 'containers/HomeModule/ShopeeImport/ShopeeImportRoutes'

const StartRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...HomeRoutes,
  ...PersonnelEmployeeRoutes,
  ...dashboardRoutes,
  ...PayrollManagementRoutes,
  ...LeaveManagementRoutes,
  ...TimeAttendanceManagementRoutes,
  ...RequisitionManagementRoutes,
  ...PersonnelRoutes,
  ...PersonnelEmployeeRoutes,
  ...claimRoutes,
  ...trainingRoutes,
  ...CompanySummaryRoutes,
  ...DigitalReportingManagementRoutes,
  ...PersonnelEmployeeRoutes,
  ...DigitalDocumentRoutes,
  ...BusinessInsightRoutes,
  ...TimesheetManagementRoutes,
  ...StaffAppraisalManagementRoutes,
  ...PersonnelEFileRoutes,
  ...ProjectExpenseRoutes,
  ...ShopeeRoutes,
  ...logOutRoutes,
  ...authenticationRoutes,
  ...StartRoute,
  ...errorRoute,
]

export default routes
