import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { useDropdownOption } from '@ifca-root/react-component/src/utils/hooks/useDropdownOption'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import Timeline from '@material-ui/lab/Timeline/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator/TimelineSeparator'
import { withStyles } from '@material-ui/styles'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  convertDate,
  convertDateToTime,
  timeElapsed,
} from 'containers/helper/commonFormats'
import { formatDate } from 'containers/helper/formatDate'
import { toTitleCase } from 'containers/helper/stringConverter'
import {
  ApprovalStatus,
  NotificationType,
  ReadStatus,
  RequestType,
  Status,
  TimeOffListingDocument,
  TimeOffStatus,
  useCompanyListingQuery,
  useGetSuperiorByEmployeeQuery,
  useGetSuperiorQuery,
  useNotificationUpdateMutation,
  useTimeOffDeleteMutation,
  useTimeOffInsertMutation,
  useTimeOffListingQuery,
  useTimeOffReasonByTlaQuery,
  useTimeOffUpdateMutation,
  useCompanyListingByEmailQuery,
  useGetUsersByAccountQuery,
  useGetEmployeeAppSecurityPermissionsByContactIdQuery,
  HrPermission,
} from 'generated/graphql'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Approvalflow from '../../../../assets/icons/approvalflow.svg'
import { Filter } from '../../../helper/SearchFilter'
let dateFormat = require('dateformat')
const CustomTimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem)

// interface TimeOffReason {
//     Reason: string
// }

interface TimeOffForm {
  TimeOffID?: string
  EmployeeName?: string
  WorkDate?: Date
  StartTime?: Date
  EndTime?: Date
  ToReasonID?: string
  Remark?: string
  EmployeeID?: string
  TimeOffStatus?: string
  VisitingPlace: string
  TravelFrom: string
  TravelTo: string
  // CompanyID?: string
}

export const TimeOffListing = (props: any) => {
  const { state }: any = useLocation()
  //console.log(state, 'STATE')
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const EmployeeDetails = JSON.parse(localStorage.getItem('Employee'))
  const [open, setOpen] = useState(false)
  const [disappear, setDisappear] = useState(true)
  const [disableConfirm, setDisableConfirm] = useState(false)
  const MenuProps = {
    disableScrollLock: true,
    disableUnderLine: true
  }
  const {
    loading: TimeOffListingLoading,
    data: { TimeOffListing } = {
      TimeOffListing: [],
    },
    refetch: TimeOffRefetch,
  } = useTimeOffListingQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
      EmployeeID: loggedInEmployee.employeeID,
    },
    fetchPolicy: 'network-only',
  })

  const TimeOffListingByEmployee = TimeOffListing.filter(
    e => e.EmployeeID === loggedInEmployee?.employeeID
  )
  // const TimeOffListingByEmployee = TimeOffListing
  console.log('TimeOffListingByEmployee', TimeOffListingByEmployee)
  // console.log("Employee ID", employee?.employeeID)

  const {
    data: { TimeOffReasonByTLA } = {
      TimeOffReasonByTLA: [],
    },
  } = useTimeOffReasonByTlaQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
    },
    fetchPolicy: 'network-only',
  })

  const [value, setValue] = useState(0)
  const {
    data: { GetEmployeeAppSecurityPermissionsByContactID } = {
      GetEmployeeAppSecurityPermissionsByContactID: [],
    },
    loading : GetEmployeeAppLoading,
  } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ContactID: JSON.parse(localStorage.getItem('Employee'))?.ContactID,
      Permissions: [
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw,
       
      

    
    
      ],
    },
  })

  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [DialogIndex, setDialogIndex] = useState(null)
  const [passObject, setpassObject] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [
    anchorElApproved,
    setAnchorElApproved,
  ] = React.useState<null | HTMLElement>(null)
  const [
    anchorElSubmittedWithdrawal,
    setAnchorElSubmittedWithdrawal,
  ] = React.useState<null | HTMLElement>(null)
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(
    null
  )

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [submitDialog, setSubmitDialog] = useState(false)
  const [OTId, setOTId] = useState(null)
  const [openApprovedHistory, setOpenApprovedHistory] = useState(false)
  const [openRejectHistory, setOpenRejectHistory] = useState(false)
  const [rejOTId, setRejOTId] = useState(null)
  const [filterSearch, setFilterSearch] = useState(state?.filterSearch || '')
  const [controlMargin, setControlMargin] = useState(false)

  const {
    data: { CompanyListingByEmail } = {
      CompanyListingByEmail: [],
    },
  } = useCompanyListingByEmailQuery({
    variables: {
      EmployeeEmail: user?.email,
    },
    fetchPolicy: 'no-cache',
  })

  const snackFunc = (showText: string, goBack: Boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
    }, 1000)
  }

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setControlMargin(false)
    setFilterSearch('')
    setValue(newValue)
  }

  const [
    TimeOffDelete,
    { loading: toDelete, error: toDeleteError },
  ] = useTimeOffDeleteMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      console.log('data', data)
      setTimeout(() => {
        history.push(`/Employee/tms/timeofflist`, {
          filterSearch: filterSearch,
        })
      }, 500)
      TimeOffRefetch()

    },
  })

  const refQuery = data => {
    return [
      {
        query: TimeOffListingDocument,

        variables: {
          // EmployeeID: employee?.employeeID,
          CompanyID: loggedInEmployee?.companyID,
        },
      },
    ]
  }

  const onDelete = (data: TimeOffForm) => {
    {
      TimeOffDelete({
        variables: {
          TimeOffID: passObject?.TimeOffID,
        },
        refetchQueries: refQuery(data),
      })
      setDeleteDialog(false)
    }
  }

  const {
    data: { getUsersByAccount } = { getUsersByAccount: [] },
  } = useGetUsersByAccountQuery({
    variables: {
      accountID: user?.accountID,
    },
    fetchPolicy: 'no-cache',
  })
  const {
    data: { GetSuperiorByEmployee } = { GetSuperiorByEmployee: [] },
    loading: GetSuperiorByEmployeeLoading,
  } = useGetSuperiorByEmployeeQuery({
    variables: {
      EmployeeID: EmployeeDetails?.EmployeeID,
    },
    fetchPolicy: 'network-only',
  })

  let SuperiorByEmployee = GetSuperiorByEmployee?.EmployeeID
  const {
    data: { GetSuperior } = { GetSuperior: [] },
    loading: GetSuperiorLoading,
  } = useGetSuperiorQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
      FromJobPositionID: EmployeeDetails?.DesignationID,
      FromOrgUnitID:
        EmployeeDetails?.OrgUnit?.OrgUnitID || loggedInEmployee?.companyID,
    },
    fetchPolicy: 'network-only',
  })

  const [notificationUpdateMutation] = useNotificationUpdateMutation()

  const [
    TimeOffInsert,
    { loading: toLoading, error: toError },
  ] = useTimeOffInsertMutation({
    onError: error => {
      snackFunc(error.message.replace('GraphQL error: ', ''), false)
    },
    onCompleted: data => {
      if (data.TimeOffInsert.TimeOffID !== '') {
        snackFunc('Successfully submitted!', false)

        // const commonInput = {
        //   CompanyID: loggedInEmployee?.companyID,
        //   ReadStatus: ReadStatus.Unread,
        //   RequestID: data.TimeOffInsert['TimeOffID'],
        //   RequestType: RequestType.TimeOff,
        //   Status: Status.Active,
        //   CreatedDT: new Date(),
        //   CreatedBy: EmployeeDetails?.EmployeeID,
        //   ModifiedDT: new Date(),
        //   ModifiedBy: EmployeeDetails?.EmployeeID,
        // }

        // if (SuperiorByEmployee) {
        //   notificationUpdateMutation({
        //     variables: {
        //       NotificationInput: {
        //         FromEmployeeID: EmployeeDetails?.EmployeeID,
        //         ToEmployeeID: SuperiorByEmployee,
        //         NotificationType: NotificationType.Approval,
        //         Message: `Time Off Approval`,
        //         IsTodo: true,
        //         ...commonInput,
        //       },
        //     },
        //   })
        // } else if (GetSuperior?.length > 0) {
        //   for (const x of GetSuperior) {
        //     notificationUpdateMutation({
        //       variables: {
        //         NotificationInput: {
        //           FromEmployeeID: EmployeeDetails?.EmployeeID,
        //           ToEmployeeID: x?.EmployeeID,
        //           NotificationType: NotificationType.Approval,
        //           Message: `Time Off Approval`,
        //           IsTodo: true,
        //           ...commonInput,
        //         },
        //       },
        //     })
        //   }
        // }
      } else {
        snackFunc('Submit Failed!', false)
      }
      setDisableConfirm(false)
      TimeOffRefetch()
      // setTimeout(() => {
      //   history.push(`/Employee/tms/timeofflist`)
      // }, 500)
    },
  })

  const [TimeOffUpdate] = useTimeOffUpdateMutation({
    onError: error => {
      // console.log('ERROR', error)
    },
    onCompleted: () => {
      snackFunc('Successfully deleted!', false)
      TimeOffRefetch()
    },
  })

  const onSubmit = (data: TimeOffForm) => {
    // console.log('this is on submit',data)
    // return
    let Document: any = null
    setDisableConfirm(true)
    let DocumentID: any = null
    DocumentID = passObject?.Document?.DocumentID

    if (
      TimeOffListingByEmployee &&
      TimeOffListingByEmployee.filter(x => x.TimeOffStatus == 'DRAFT').length >
        0
    ) {
      TimeOffInsert({
        variables: {
          input: {
            CreatedBy: passObject?.CreatedBy,
            CreatedDT: passObject?.CreatedDT,
            TimeOffID: passObject?.TimeOffID,
            EmployeeID: loggedInEmployee?.employeeID,
            Remark: passObject?.Remark,
            WorkDate: passObject?.WorkDate,
            StartTime: passObject?.StartTime,
            EndTime: passObject?.EndTime,
            DocumentID: DocumentID,
            ToReasonID: passObject?.ToReasonID,
            TimeOffStatus: TimeOffStatus.Pending,
            VisitingPlace: passObject?.VistingPlace,
            TravelFrom: passObject?.TravelFrom,
            TravelTo: passObject?.TravelTo,
            CompanyID: loggedInEmployee?.companyID,
          },
        },
        refetchQueries: refQuery(data),
      })
      setSubmitDialog(false)
    } else {
      TimeOffInsert({
        variables: {
          input: {
            EmployeeID: loggedInEmployee?.employeeID,
            Remark: passObject?.Remark,
            WorkDate: passObject?.WorkDate,
            StartTime: passObject?.StartTime,
            EndTime: passObject?.EndTime,
            DocumentID: DocumentID,
            ToReasonID: passObject?.ToReasonID,
            TimeOffStatus: TimeOffStatus.Pending,
            VisitingPlace: passObject?.VistingPlace,
            TravelFrom: passObject?.TravelFrom,
            TravelTo: passObject?.TravelTo,
            CompanyID: loggedInEmployee?.companyID,
          },
        },
        refetchQueries: refQuery(data),
      })
      // }
      setSubmitDialog(false)
    }
  }

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemObj: any,
    index: number
  ) => {
    setDialogIndex(index)
    setpassObject(itemObj)

    switch (itemObj?.TimeOffStatus) {
      case 'PENDING':
      case 'Withdraw':
        setAnchorElSubmittedWithdrawal(event.currentTarget)
        break
      case 'APPROVED':
        setAnchorElApproved(event.currentTarget)
        break
      case 'DRAFT':
        setAnchorEl(event.currentTarget)
        break
      case 'REJECTED':
      case 'Withdrawn':
      case 'CANCELLED':
        setAnchorElView(event.currentTarget)
    }
    //setAnchorEl(event.currentTarget)
  }
  console.log(passObject)

  const [WorkFlowData, setWorkFlowData] = useState(null)
  const [openWorkFlow, setWorkFlow] = useState(false)
  const [deleteApproveDialog, setDeleteApproveDialog] = useState(false)
  const [cancelRemark, setCancelRemark] = useState('')

  const WorkFlowClicked = (ID: string) => {
    let Data = TimeOffListing?.find(x => x.TimeOffID === ID)
    setWorkFlowData(Data)
    // setWorkFlowIndex(index)
    console.log(Data, 'workflow')
    setWorkFlow(true)
  }

  let userCategories = [
    { id: 'All' },
    { id: 'Approved' },
    { id: 'Submitted' },
    { id: 'Draft' },
    { id: 'Rejected' },
    { id: 'Withdrawn' },
    { id: 'Cancelled' },
    { id: 'Withdrawal' },
  ]
  const [statusType, setStatusType] = useState('All')
  const [checked, setChecked] = useState(false)
  const [filteredList, setFilteredList] = useState([])
  const dropdownClick = (select: any) => {
    if (select == 'All') {
      setStatusType(select)
    }

    if (
      select == 'Approved' ||
      'Submitted' ||
      'Draft' ||
      'Rejected' ||
      'Withdrawn' ||
      'Cancelled' ||
      'Withdrawal'
    ) {
      if (checked == false) {
        setChecked(true)
        setStatusType(select)
      }

      if (checked == true) {
        setChecked(false)
        setStatusType(select)
      }
    }
  }

  useEffect(() => {
    if (TimeOffListing?.length !== 0) {
      if (statusType !== 'All') {
        if (statusType == 'Approved') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Approved
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        } else if (statusType == 'Submitted') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Pending
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        } else if (statusType == 'Draft') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Draft
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        } else if (statusType == 'Cancelled') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Cancelled
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        } else if (statusType == 'Withdrawal') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Withdraw
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        } else if (statusType == 'Withdrawn') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Withdrawn
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        } else if (statusType == 'Rejected') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Rejected
          )
          setFilteredList(filterForCategory)
          // setChecked(false)
          return
        }
        // setRender(true)
        else return
      } else {
        let filterForCategory: any = TimeOffListing
        setFilteredList(filterForCategory)
        // setChecked(false)
      }
    } else {
      setFilteredList([])
    }
  }, [TimeOffListing, statusType])

  const {
    data: { CompanyListing } = { CompanyListing: [] },
    loading: companyListingLoading,
  } = useCompanyListingQuery({
    variables: {
      SubscriptionAccountID: user?.accountID,
    },
    fetchPolicy: 'no-cache',
  })

  const handleWithdraw = () => {
    console.log('passObjectpassObject', passObject)
    console.log('passObjectpassObject', cancelRemark)

    let Items: any = []

    Items.push({
      ModifiedDT: new Date(),
      ModifiedBy: loggedInEmployee?.subscriptionAccountID,
      TimeOffID: passObject?.TimeOffID,
      TimeOffStatus: passObject?.TimeOffStatus,
      // Approver: employee?.name,
      // ApproverID: employee?.ID,
      ApproveDate: new Date(),
      CompanyID: loggedInEmployee?.companyID,
      EmployeeID: passObject?.EmployeeID,
      Reason: cancelRemark,
    })

    if (new Date() > new Date(passObject?.WorkDate) && passObject?.TimeOffStatus == 'APPROVED') {
      snackFunc('Time Off date applied is later than current date', false)
    } else {
      if (passObject?.TimeOffStatus == 'APPROVED') {
        TimeOffUpdate({
          variables: {
            input: Items,
            ActionStatus: 'Withdraw',
          },
        })
      } else {
        TimeOffUpdate({
          variables: {
            input: Items,
            ActionStatus: 'CANCELLED',
          },
        })
      }
    }
    TimeOffRefetch()
    setDeleteApproveDialog(false)
    setCancelRemark('')
  }

  return (
    <>
      {TimeOffListingLoading ? (
        <Loading />
      ) : (
        <>
          <MainHeader
            onClick={() => {
              history.push(`/home/timeattendance`)
            }}
            mainBtn="back"
            // title={`${loggedInEmployee?.Salutation} ${loggedInEmployee?.name}`}
            title={
              loggedInEmployee?.Salutation != ''
                ? `${loggedInEmployee?.Salutation} ${loggedInEmployee?.name}`
                : `${loggedInEmployee?.name}`
            }
            smTitle={'Employee Super App'}
            routeSegments={[
              { name: `Time Attendance` },
              { name: `Time Attendance` },
              { name: `My Time Off`, current: true },
            ]}
          />
          <DynamicSubHeader
            title={
              <>
                <span className="xsTitle" style={{ color: 'orange' }}>
                  {loggedInEmployee?.companyName}
                </span>
              </>
            }
          />
          <div
            className='search-header-flex fixed-search-header with-dynamic-header'
          >
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <SearchHeader
                  input={filterSearch}
                  searchtext={filterSearch}
                  issearch={filterSearch ? true : false}
                  title="Timeoff Listing"
                  value={filteredList?.length?.toString()}
                  search
                  onChangeAction={e => {
                    setFilterSearch(e.target.value)
                  }}
                  onCloseAction={e => setFilterSearch('')}
                />
              </Grid>
              <Grid item xs={4}>
                <List
                  disablePadding
                  className='search-header-dropdown'
                >
                  <ListItem
                    button
                  >
                    <Select
                      fullWidth
                      MenuProps={MenuProps}
                      disableUnderline
                      name="statusType"
                      value={statusType}
                      //InputProps={{ disableUnderline: true }}
                      onChange={e =>
                        // setCategory(e.target.value)
                        dropdownClick(e.target.value)
                      }
                    >
                      {userCategories.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </div>
          <ContentWrapper singlesubHeader float>
            {/* <div className="search-filter-booking">
              <SearchHeader
                input={filterSearch}
                searchtext={filterSearch}
                issearch={filterSearch ? true : false}
                half
                title="Timeoff Listing"
                value={filteredList?.length?.toString()}
                search
                onChangeAction={e => {
                  setFilterSearch(e.target.value)
                }}
                onCloseAction={e => setFilterSearch('')}
              />

              <div className="filter-dropdown">
                <List disablePadding className="search-header-booking">
                  <ListItem
                    style={{
                      paddingTop: '12px',
                      paddingRight: '12px',
                      paddingBottom: '8px',
                    }}
                    button
                    //  onClick={e => setAnchorEl(e.currentTarget)}
                  >
                    <TextField
                      fullWidth
                      select
                      name="statusType"
                      value={statusType}
                      InputProps={{ disableUnderline: true }}
                      onChange={e =>
                        // setCategory(e.target.value)
                        dropdownClick(e.target.value)
                      }
                    >
                      {userCategories.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.id}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                </List>
              </div>
            </div> */}

            <List className="core-list">
              {filteredList === undefined || filteredList?.length === 0 ? (
                <EmptyList
                  title="No Record found"
                  subtitle="Add a new record now."
                />
              ) : null}

              {filteredList
                ?.filter(
                  emp =>
                    filterSearch === '' ||
                    emp?.Remark.toLowerCase().includes(
                      filterSearch.toLowerCase()
                    ) ||
                    formatDate(emp?.WorkDate)
                      .toLowerCase()
                      .includes(filterSearch.toLowerCase())
                )
                .sort((a, b) => (a?.WorkDate < b?.WorkDate ? -1 : 1))
                .map((x, index) => {
                  // let workFlow = WorkflowHelper(v?.WorkFlow?.WorkFlowStep)
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc flex-space ">
                              <span className="xsTitle">
                                {`WD: ${formatDate(x?.WorkDate)}`}
                              </span>

                              <span>
                                {' '}
                                | {`${timeElapsed(x?.EndTime, x?.StartTime)}`}
                              </span>
                            </span>
                            {x?.Document && (
                              <AttachFileIcon
                                style={{ fontSize: '0.8rem' }}
                                onClick={() =>
                                  window.open(
                                    x?.Document?.DocumentFile,
                                    '_blank'
                                  )
                                }
                              />
                            )}
                            <span className="text desc">
                              {formatDate(x?.CreatedDT)}
                            </span>{' '}
                          </>
                        }
                        secondary={
                          <>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                                width: '100%',
                              }}
                            >
                              <span className="desc">
                                <span>
                                  {`${convertDate(
                                    x?.StartTime
                                  )} - ${convertDate(x?.EndTime)} `}
                                </span>
                              </span>
                              <span className="desc">
                                {`${
                                  x?.TimeOffStatus === TimeOffStatus.Withdraw
                                    ? 'Withdrawal'
                                    : ''
                                }`}
                              </span>
                            </span>

                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                                width: '100%',
                              }}
                            >
                              <span className="desc">
                                {`Remark: ${x?.Remark}`}
                              </span>

                              {x?.TimeOffStatus !== TimeOffStatus.Draft ? (
                                <>
                                  <span
                                    className="desc"
                                    style={{
                                      fontSize: '10px',
                                      color: `${
                                        x?.TimeOffStatus === 'CANCELLED' ||
                                        x?.TimeOffStatus === 'REJECTED'
                                          ? 'red'
                                          : x?.TimeOffStatus === 'APPROVED' ||
                                            x?.TimeOffStatus === 'Withdrawn'
                                          ? 'lime'
                                          : x?.TimeOffStatus === 'PENDING' ||
                                            x?.TimeOffStatus === 'Withdraw'
                                          ? 'blue'
                                          : null
                                      }`,
                                    }}
                                    onClick={e => {
                                      // e.stopPropagation()
                                      WorkFlowClicked(x?.TimeOffID)
                                    }}
                                  >
                                    <img
                                      className="desc"
                                      src={Approvalflow}
                                      style={{ width: '18px' }}
                                    />
                                    &nbsp;{' '}
                                    <span>
                                      {x?.TimeOffStatus ==
                                        TimeOffStatus.Pending ||
                                      x?.TimeOffStatus == TimeOffStatus.Withdraw
                                        ? 'Submitted'
                                        : x?.TimeOffStatus ==
                                          TimeOffStatus.Withdrawn
                                        ? 'Approved'
                                        : toTitleCase(x?.TimeOffStatus)}
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <span
                                  className="desc"
                                  style={{
                                    color: 'gray',
                                  }}
                                >
                                  {toTitleCase(x?.TimeOffStatus)}
                                </span>
                              )}
                            </span>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                      {
                            user?.superUser ||
                            GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                              HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel) ||
                              GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                              HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit) ||
                              GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                              HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw) ||
                              GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                              HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate) ||
                              GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                              HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete
                            ) ? (
                        <IconButton
                          edge="end"
                          aria-label="more"
                          style={{ marginBottom: '1.5rem' }}
                          onClick={e => {
                            handleMoreClick(e, x, index)
                          }}
                        >
                          <MoreVert fontSize="small" />
                        </IconButton>
                                                    ) : null
                                                  }
                                         
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </List>
            {
               user?.superUser ||
               GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                 HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate
               ) ? (
            <FloatButton
              onClick={() =>
                history.push('/Employee/tms/timeoff-application', {
                  filterSearch: filterSearch,
                })
              }
            />
            ) : null
          }
    
            <Menu
disableScrollLock={true}
              id="simple-menu"
              anchorEl={anchorElApproved}
              keepMounted
              open={Boolean(anchorElApproved)}
              onClose={() => setAnchorElApproved(null)}
            >

{
                user?.superUser ||
                GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                  HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead
                ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  history.push(`/Employee/tms/timeoffdetails`, {
                    data: passObject,
                    filterSearch: filterSearch,
                  })
                }}
              >
                View
              </MenuItem>
                              ) : null
                            }
                         {
                           user?.superUser ||
                           GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                             HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw
                           ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  setDeleteApproveDialog(true)
                  setAnchorElApproved(null)
                }}
              >
                Withdraw
              </MenuItem>
                           ) : null
                          }
                          
            </Menu>

            <Menu
disableScrollLock={true}
              id="simple-menu"
              anchorEl={anchorElSubmittedWithdrawal}
              keepMounted
              open={Boolean(anchorElSubmittedWithdrawal)}
              onClose={() => setAnchorElSubmittedWithdrawal(null)}
            >
              {
                     user?.superUser ||
                     GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                       HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead
                     ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  history.push(`/Employee/tms/timeoffdetails`, {
                    data: passObject,
                    filterSearch: filterSearch,
                  })
                }}
              >
                View
              </MenuItem>
                                   ) : null
                                  }
                                {
                                                 user?.superUser ||
                                                 GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                                                   HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel
                                                 ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  setDeleteApproveDialog(true)
                  setAnchorElSubmittedWithdrawal(null)
                }}
              >
                Cancel
              </MenuItem>
                                           ) : null
                                          }
                                        
            </Menu>

            <Menu
disableScrollLock={true}
              id="simple-menu"
              anchorEl={anchorElView}
              keepMounted
              open={Boolean(anchorElView)}
              onClose={() => setAnchorElView(null)}
            >
              {
                
                user?.superUser ||
                GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                  HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead
                ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  history.push(`/Employee/tms/timeoffdetails`, {
                    data: passObject,
                    filterSearch: filterSearch,
                  })
                }}
              >
                View
              </MenuItem>
                              ) : null
                            }
                         
            </Menu>

            <Menu
disableScrollLock={true}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={e => {
                setAnchorEl(null)
              }}
            >
                            {
                      user?.superUser ||
                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate
                      ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  history.push(`/Employee/tms/timeoff-application/edit`, {
                    TimeOffListing: passObject,
                    filterSearch: filterSearch,
                  })
                }}
              >
                Edit
              </MenuItem>
                                    ) : null
                                  }
                                  {
                                      user?.superUser ||
                                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit
                                      ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  setSubmitDialog(true)
                  setAnchorEl(null)
                }}
              >
                Submit
              </MenuItem>
                                ) : null
                              }
                           {
                               user?.superUser ||
                               GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                                 HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete
                               ) ? (
              <MenuItem
                className="drawer-dropdown"
                onClick={() => {
                  setDeleteDialog(true)
                  setAnchorEl(null)
                }}
              >
                Delete
              </MenuItem>
                             ) : null
                            }
                            
            </Menu>
            <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

            <StandardDialog
              fullWidth={true}
              open={submitDialog}
              onClose={() => setSubmitDialog(false)}
              sections={{
                header: {
                  title: 'Submit Time Off',
                },
                body: () => (
                  <>
                    <div>
                      <div>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Work Date
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {formatDate(passObject?.WorkDate)}
                      </div>
                      <div style={{ marginTop: '10px' }}></div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            Start Time
                          </span>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            End Time
                          </span>
                        </div>
                      </div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span>
                            {convertDateToTime(passObject?.StartTime)}
                          </span>
                        </div>
                        <div style={{ width: '50%', float: 'right' }}>
                          <span
                            className="extra-row mdDesc"
                            // style={{marginLeft: '118px' }}
                          >
                            {convertDateToTime(passObject?.EndTime)}
                          </span>
                        </div>
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Duration
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {timeElapsed(
                          passObject?.EndTime,
                          passObject?.StartTime
                        )}
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Visiting Place
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {passObject?.VisitingPlace}
                      </div>
                      <div style={{ marginTop: '10px' }}></div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            Travel From
                          </span>
                        </div>
                        <div style={{ width: '50%', float: 'right' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            Travel To
                          </span>
                        </div>
                      </div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span>{passObject?.TravelFrom}</span>
                        </div>
                        <div style={{ width: '50%', float: 'right' }}>
                          <span
                          // style={{marginLeft: '114px' }}
                          >
                            {passObject?.TravelTo}
                          </span>
                        </div>
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Time Off Reason
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {TimeOffReasonByTLA.filter(
                          e => e.TimeOffReasonID === passObject?.ToReasonID
                        ).map(f => f.Name)}
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Remark
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {passObject?.Remark}
                      </div>

                      {/* <div style={{ marginTop: '3px' }}>
                                  <span
                                    className="extra-row mdDesc"
                                    style={{ color: '#808080' }}
                                  >
                                    Travel To
                                  </span>
                                </div>
                                <div className="extra-row mdDesc">
                                  {passObject?.TravelTo}
                                </div> */}
                      {/* </div> */}
                    </div>
                  </>
                ),
                footer: {
                  actions: [
                    {
                      displayText: 'Cancel',
                      props: {
                        onClick: e => {
                          e.stopPropagation()
                          setSubmitDialog(false)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: e => {
                          // alert("Confirm Submit")
                          e.stopPropagation()
                          onSubmit(DialogIndex)
                        },
                        variant: 'contained',
                        color: 'primary',
                        disabled: disableConfirm,
                      },
                    },
                  ],
                },
              }}
            />

            <StandardDialog
              fullWidth={true}
              open={deleteDialog}
              onClose={() => setDeleteDialog(false)}
              sections={{
                header: {
                  title: 'Delete Time Off',
                },
                body: () => (
                  <>
                    <div>
                      <div>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Work Date
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {formatDate(passObject?.WorkDate)}
                      </div>
                      <div style={{ marginTop: '10px' }}></div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            Start Time
                          </span>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            End Time
                          </span>
                        </div>
                      </div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span>
                            {convertDateToTime(passObject?.StartTime)}
                          </span>
                        </div>
                        <div style={{ width: '50%', float: 'right' }}>
                          <span
                            className="extra-row mdDesc"
                            // style={{marginLeft: '118px' }}
                          >
                            {convertDateToTime(passObject?.EndTime)}
                          </span>
                        </div>
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Duration
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {timeElapsed(
                          passObject?.EndTime,
                          passObject?.StartTime
                        )}
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Visiting Place
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {passObject?.VisitingPlace}
                      </div>
                      <div style={{ marginTop: '10px' }}></div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            Travel From
                          </span>
                        </div>
                        <div style={{ width: '50%', float: 'right' }}>
                          <span
                            className="extra-row mdDesc"
                            style={{ color: '#808080' }}
                          >
                            Travel To
                          </span>
                        </div>
                      </div>
                      <div className="extra-row mdDesc">
                        <div style={{ width: '50%', float: 'left' }}>
                          <span>{passObject?.TravelFrom}</span>
                        </div>
                        <div style={{ width: '50%', float: 'right' }}>
                          <span
                          // style={{marginLeft: '114px' }}
                          >
                            {passObject?.TravelTo}
                          </span>
                        </div>
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Time Off Reason
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {TimeOffReasonByTLA.filter(
                          e => e.TimeOffReasonID === passObject?.ToReasonID
                        ).map(f => f.Name)}
                      </div>
                      <div style={{ marginTop: '3px' }}>
                        <span
                          className="extra-row mdDesc"
                          style={{ color: '#808080' }}
                        >
                          Remark
                        </span>
                      </div>
                      <div className="extra-row mdDesc">
                        {passObject?.Remark}
                      </div>
                    </div>
                  </>
                ),
                footer: {
                  actions: [
                    {
                      displayText: 'Cancel',
                      props: {
                        onClick: e => {
                          e.stopPropagation()
                          setDeleteDialog(false)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: e => {
                          e.stopPropagation()
                          // alert("Confirm Delete")
                          onDelete(DialogIndex)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
                },
              }}
            />

            <CommonDialog
              fullWidth={true}
              open={deleteApproveDialog}
              onClose={() => setDeleteApproveDialog(false)}
              sections={{
                header: {
                  dynamic: (
                    <div className="">
                      <div className="dialog-dynamic-content">
                        <div className="session">
                          <div className="smTitle c-orange flex-space">
                            {' '}
                            {`${passObject?.Employee?.Contact?.FullName} | ${passObject?.Employee?.EmployeeNo}`}
                          </div>
                        </div>
                      </div>
                      <div className="infoline-content">
                        <div className="infoline">{`${'Time Off Withdrawal'}`}</div>
                      </div>
                    </div>
                  ),
                },
                body: () => (
                  <>
                    <div className="content-wrap full">
                      <span className="desc" style={{ color: 'grey' }}>
                        Date
                      </span>
                      <div className="mdDesc">
                        {dateFormat(passObject?.WorkDate, 'mmm dd yyyy')}
                      </div>
                    </div>

                    <Grid container>
                      <Grid item xs={6}>
                        <div className="content-wrap">
                          <span className="desc" style={{ color: 'grey' }}>
                            Start Time
                          </span>
                          <div className="mdDesc">
                            {dateFormat(passObject?.StartTime, 'hh:MM TT')}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="content-wrap">
                          <span className="desc" style={{ color: 'grey' }}>
                            End Time
                          </span>
                          <div className="mdDesc">
                            {dateFormat(passObject?.EndTime, 'hh:MM TT')}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <div className="content-wrap full">
                      <span className="desc" style={{ color: 'grey' }}>
                        Duration
                      </span>
                      <div className="mdDesc">
                        {timeElapsed(
                          passObject?.EndTime,
                          passObject?.StartTime
                        )}
                      </div>
                    </div>

                    <TextField
                      label="Remark"
                      name="Remark"
                      fullWidth
                      margin="normal"
                      onChange={e => {
                        setCancelRemark(e.target.value)
                      }}
                      required
                    />
                  </>
                ),
                footer: {
                  actions: [
                    {
                      displayText: 'Cancel',
                      props: {
                        onClick: () => {
                          setCancelRemark('')
                          setDeleteApproveDialog(false)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleWithdraw()
                        },
                        disabled: !cancelRemark,
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
                },
              }}
            />
            <CommonDialog
              fullWidth={true}
              open={openWorkFlow}
              onClose={() => setWorkFlow(false)}
              sections={{
                header: {
                  dynamic: (
                    <div className="">
                      <div className="dialog-dynamic-content">
                        <div className="session">
                          {/* <div className="smTitle">SmTitle</div> */}
                          <div className="flex session">
                            <div className="title c-orange flex-space">
                              {'TimeOff Application'}
                            </div>
                            <div className="rightText c-orange">
                              {WorkFlowData?.TimeOffStatus == 'PENDING'
                                ? 'Submitted'
                                : toTitleCase(WorkFlowData?.TimeOffStatus)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="infoline-content">
                        <div className="xsTitle flex-space">
                          {'Approval Log'}
                        </div>
                        <div className=" xsTitle rightText"> {''}</div>
                      </div>
                    </div>
                  ),
                },
                body: () => (
                  <>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >{`Submitter`}</Typography>
                    <PersonIcon style={{ fontSize: 'small' }} />
                    <Typography variant="caption">
                      {' '}
                      {WorkFlowData?.WorkFlow?.SubmitterName ||
                        getUsersByAccount.find(
                          x => x.ID == WorkFlowData?.CreatedBy
                        )?.name ||
                        WorkFlowData?.Employee?.Contact?.FullName}
                    </Typography>{' '}
                    <br />
                    <EventIcon style={{ fontSize: 'small' }} />
                    <Typography variant="caption">
                      {' '}
                      {dateFormat(
                        new Date(
                          WorkFlowData?.WorkFlow?.SubmitDate ||
                            WorkFlowData?.CreatedDT ||
                            new Date()
                        ),
                        'dd/mm/yyyy h:MM TT'
                      )}{' '}
                    </Typography>{' '}
                    <Divider orientation="horizontal" variant="fullWidth" />
                    <br />
                    <Timeline className="timeline">
                      {WorkFlowData?.WorkFlow ? (
                        WorkFlowData?.WorkFlow?.WorkFlowStep.map(x => (
                          <>
                            <CustomTimelineItem>
                              <TimelineSeparator>
                                <TimelineDot
                                  style={
                                    x.ApprovalStatus === ApprovalStatus.Approved
                                      ? {
                                          backgroundColor: 'green',
                                        }
                                      : x.ApprovalStatus ===
                                        ApprovalStatus.Rejected || x.ApprovalStatus ===
                                        ApprovalStatus.Cancelled
                                      ? {
                                          backgroundColor: 'red',
                                        }
                                      : x.NotifiedDT
                                      ? { backgroundColor: '#ffb11f' }
                                      : {}
                                  }
                                />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography variant="subtitle1">
                                  {`Level ${x.StepNo}`} &nbsp;
                                  <span
                                    style={
                                      x?.ApprovalStatus === 'Pending'
                                        ? {
                                            color: '#ffb11f',
                                            fontSize: '12px',
                                          }
                                        : x?.ApprovalStatus === 'Rejected' || x?.ApprovalStatus === 'Cancelled'
                                        ? {
                                            color: 'red',
                                            fontSize: '12px',
                                          }
                                        : {
                                            color: 'green',
                                            fontSize: '12px',
                                          }
                                    }
                                  >
                                    {x?.ApprovalStatus ===
                                    ApprovalStatus.Withdraw
                                      ? 'Approved'
                                      : x?.ApprovalStatus}
                                  </span>
                                </Typography>
                                {x?.WorkFlowApprovedStep?.length > 0 ? (
                                  <>
                                    <PersonIcon style={{ fontSize: 'small' }} />
                                    <Typography variant="caption">
                                      {' '}
                                      {
                                        x?.WorkFlowApprovedStep[0]?.ApproverName
                                      }{' '}
                                    </Typography>{' '}
                                    <br />
                                    <EventIcon style={{ fontSize: 'small' }} />
                                    <Typography variant="caption">
                                      {' '}
                                      {dateFormat(
                                        new Date(
                                          x?.WorkFlowApprovedStep[0]?.ApprovalDT
                                        ),
                                        'dd/mm/yyyy h:MM TT'
                                      )}{' '}
                                    </Typography>{' '}
                                    <br />
                                    <Typography variant="caption">
                                      {' '}
                                      {
                                        x?.WorkFlowApprovedStep[0]
                                          ?.StatusRemarks
                                      }{' '}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography variant="body2">
                                      {' '}
                                      {x?.RoleName}{' '}
                                    </Typography>
                                  </>
                                )}
                              </TimelineContent>
                            </CustomTimelineItem>
                          </>
                        ))
                      ) : (
                        <>
                          {WorkFlowData?.TimeOffStatus !==
                            TimeOffStatus.Pending && (
                            <CustomTimelineItem>
                              <TimelineSeparator>
                                <TimelineDot
                                  style={
                                    WorkFlowData?.TimeOffStatus ===
                                    TimeOffStatus.Approved
                                      ? {
                                          backgroundColor: 'green',
                                        }
                                      : WorkFlowData?.TimeOffStatus ===
                                          TimeOffStatus.Rejected ||
                                        WorkFlowData?.TimeOffStatus ===
                                          TimeOffStatus.Cancelled
                                      ? {
                                          backgroundColor: 'red',
                                        }
                                      : {}
                                  }
                                />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography variant="subtitle1">
                                  <span
                                    style={
                                      WorkFlowData?.TimeOffStatus ===
                                        'PENDING' ||
                                      WorkFlowData?.TimeOffStatus === 'Withdraw'
                                        ? {
                                            color: '#ffb11f',
                                            fontSize: '12px',
                                          }
                                        : WorkFlowData?.TimeOffStatus ===
                                            'REJECTED' ||
                                          WorkFlowData?.TimeOffStatus ===
                                            TimeOffStatus.Cancelled
                                        ? {
                                            color: 'red',
                                            fontSize: '12px',
                                          }
                                        : {
                                            color: 'green',
                                            fontSize: '12px',
                                          }
                                    }
                                  >
                                    {WorkFlowData?.TimeOffStatus ===
                                    TimeOffStatus.Withdraw
                                      ? 'Approved'
                                      : toTitleCase(
                                          WorkFlowData?.TimeOffStatus
                                        )}
                                  </span>
                                </Typography>

                                <>
                                  <PersonIcon style={{ fontSize: 'small' }} />
                                  <Typography variant="caption">
                                    {' '}
                                    {WorkFlowData?.Approver}{' '}
                                  </Typography>{' '}
                                  <br />
                                  <EventIcon style={{ fontSize: 'small' }} />
                                  <Typography variant="caption">
                                    {' '}
                                    {WorkFlowData?.ApproveDate
                                      ? dateFormat(
                                          new Date(WorkFlowData?.ApproveDate),
                                          'dd/mm/yyyy h:MM TT'
                                        )
                                      : null}{' '}
                                  </Typography>{' '}
                                  <br />
                                  <Typography variant="caption">
                                    {' '}
                                    {WorkFlowData?.RejectionRemarks}{' '}
                                  </Typography>
                                </>
                              </TimelineContent>
                            </CustomTimelineItem>
                          )}
                        </>
                      )}
                    </Timeline>
                  </>
                ),
                footer: {
                  actions: [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => {
                          setWorkFlow(false)
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
                },
              }}
            />
          </ContentWrapper>
        </>
      )}
    </>
  )
}
