import React, { lazy } from 'react'

const EmploymentInfo = lazy(() =>
  import('./PersonnelEmployeeSubmenu').then(module => ({
    default: module.PersonnelEmployeeSubmenu,
  }))
)

const JobInfo = lazy(() =>
  import('./EmployeeJobInfo/EmployeeJobInfoForm').then(module => ({
    default: module.EmployeeJobInfoForm,
  }))
)

const StatutoryInfo = lazy(() =>
  import('./EmployeeStatutoryInfo/StatutoryInfoForm').then(module => ({
    default: module.StatutoryInfoForm,
  }))
)

const TP3 = lazy(() =>
  import('./EmployeeTP3/TP3Form').then(module => ({
    default: module.TP3Form,
  }))
)
const ZakatListing = lazy(() =>
  import('./EmployeeZakatInfo/EmployeeZakatListing').then(module => ({
    default: module.ZakatListing,
  }))
)

const ZakatForm = lazy(() =>
  import('./EmployeeZakatInfo/EmployeeZakatForm').then(module => ({
    default: module.ZakatForm,
  }))
)
const AssetAssignedListing = lazy(() =>
  import('./EmployeeAssetAssigned/AssetAssignedListing').then(module => ({
    default: module.AssetAssignedListing,
  }))
)

const AssetAssignedForm = lazy(() =>
  import('./EmployeeAssetAssigned/AssetAssignedForm').then(module => ({
    default: module.AssetAssignedForm,
  }))
)

const DiaryListing = lazy(() =>
  import('./EmployeeDiary/DiaryListing').then(module => ({
    default: module.DiaryListing,
  }))
)

const DiaryForm = lazy(() =>
  import('./EmployeeDiary/DiaryForm').then(module => ({
    default: module.DiaryForm,
  }))
)

const InsuranceSchemeListing = lazy(() =>
  import('./EmployeeInsuranceScheme/InsuranceSchemeListing').then(module => ({
    default: module.InsuranceSchemeListing,
  }))
)

const InsuranceSchemeForm = lazy(() =>
  import('./EmployeeInsuranceScheme/InsuranceSchemeForm').then(module => ({
    default: module.InsuranceSchemeForm,
  }))
)

const AppraisalRecordListing = lazy(() =>
  import('./EmployeeAppraisalRecord/AppraisalRecordListing').then(module => ({
    default: module.AppraisalRecordListing,
  }))
)

const AppraisalRecordForm = lazy(() =>
  import('./EmployeeAppraisalRecord/AppraisalRecordForm').then(module => ({
    default: module.AppraisalRecordForm,
  }))
)

const TrainingRecordListing = lazy(() =>
  import('./EmployeeTrainingRecord/TrainingRecordListing').then(module => ({
    default: module.TrainingRecordListing,
  }))
)

const TrainingRecordForm = lazy(() =>
  import('./EmployeeTrainingRecord/TrainingRecordForm').then(module => ({
    default: module.TrainingRecordForm,
  }))
)

const CareerMovementListing = lazy(() =>
  import('./EmployeeCareerMovement/CareerMovementListing').then(module => ({
    default: module.CareerMovementListing,
  }))
)

const CareerMovementForm = lazy(() =>
  import('./EmployeeCareerMovement/CareerMovementForm').then(module => ({
    default: module.CareerMovementForm,
  }))
)

const Subordinate = lazy(() =>
  import('./EmployeeSubordinate/SubordinateListing').then(module => ({
    default: module.SubordinateListing,
  }))
)
const DigitalDocument = lazy(() =>
  import('./EmployeeDigitalRecords/DigitalDocumentNav').then(module => ({
    default: module.DigitalDocumentNav,
  }))
)

const PersonnelEmployeeRoutes = [
  {
    props: { exact: true, path: '/personnelemployeelisting/employmentinfo' },
    component: <EmploymentInfo />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/jobinfoform/edit',
    },
    component: <JobInfo mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/jobinfoform/add',
    },
    component: <JobInfo mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/statutoryinfoform',
    },
    component: <StatutoryInfo />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/tp3form',
    },
    component: <TP3 />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/zakatlisting',
    },
    component: <ZakatListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/zakat/zakatform',
    },
    component: <ZakatForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/assetassignedlisting',
    },
    component: <AssetAssignedListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/assetassignedform',
    },
    component: <AssetAssignedForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/diarylisting',
    },
    component: <DiaryListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/diaryform/add',
    },
    component: <DiaryForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/diaryform/edit',
      // path: '/Employee/DiaryForm/:diaryID/edit',
    },
    component: <DiaryForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/insuranceschemelisting',
    },
    component: <InsuranceSchemeListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/insuranceschemeform/add',
    },
    component: <InsuranceSchemeForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/insuranceschemeform/edit',
    },
    component: <InsuranceSchemeForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/appraisalrecordlisting',
    },
    component: <AppraisalRecordListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/appraisalrecordform/add',
    },
    component: <AppraisalRecordForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/appraisalrecordform/edit',
      // path: '/Employee/AppraisalRecordForm/:appraisalRecordID/edit',
    },
    component: <AppraisalRecordForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/trainingrecordlisting',
    },
    component: <TrainingRecordListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/trainingrecordform/add',
    },
    component: <TrainingRecordForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/trainingrecordform/edit',
      // path: '/Employee/TrainingRecordForm/:trainingID/edit',
    },
    component: <TrainingRecordForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/careermovementlisting',
    },
    component: <CareerMovementListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/careermovementform/new',
    },
    component: <CareerMovementForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/careermovementform/edit',
    },
    component: <CareerMovementForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/subordinatelisting',
    },
    component: <Subordinate />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting/employmentinfo/digitalDocumentNav',
    },
    component: <DigitalDocument />,
  },
]

export default PersonnelEmployeeRoutes
