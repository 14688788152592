import React, { lazy } from 'react'

const ModerationTable = lazy(() =>
  import('./ModerationTable/ModerationTable').then(module => ({
    default: module.ModerationTable,
  }))
)

const StaffKpiListing = lazy(() =>
  import('./StaffKpi/StaffKpiListing').then(module => ({
    default: module.StaffKpiListing,
  }))
)

const StaffVersion = lazy(() =>
  import('./StaffKpi/StaffVersion').then(module => ({
    default: module.StaffVersion,
  }))
)

const StaffKpiForm = lazy(() =>
  import('./StaffKpi/StaffKpiForm').then(module => ({
    default: module.StaffKpiForm,
  }))
)

const StaffKpiDetailForm = lazy(() =>
  import('./StaffKpi/StaffKpiDetailForm').then(module => ({
    default: module.StaffKpiDetailForm,
  }))
)

const ScaleForm = lazy(() =>
  import('./StaffKpi/ScaleForm').then(module => ({
    default: module.ScaleForm,
  }))
)

const StaffEmployeeApprasialListing = lazy(() =>
  import('./StaffAppraisal/StaffEmployeeApprasialListing').then(module => ({
    default: module.StaffEmployeeApprasialListing,
  }))
)

const StaffAppraisalListing = lazy(() =>
  import('./StaffAppraisal/Appraisal/StaffAppraisalListing').then(module => ({
    default: module.StaffAppraisalListing,
  }))
)

const StaffAppraisalForm = lazy(() =>
  import('./StaffAppraisal/Appraisal/StaffAppraisalForm').then(module => ({
    default: module.StaffAppraisalForm,
  }))
)

const StaffAppraisalInnerForm = lazy(() =>
  import('./StaffAppraisal/Appraisal/StaffAppraisalInnerForm').then(module => ({
    default: module.StaffAppraisalInnerForm,
  }))
)

const StaffPerformanceAnalysisSummary = lazy(() =>
  import('./StaffPerformanceAnalysis/StaffPerformanceAnalysisSummary').then(module => ({
    default: module.StaffPerformanceAnalysisSummary,
  }))
)

const BonusSimulationListing = lazy(() =>
  import('./BonusSimulation/BonusSimulationListing').then(module => ({
    default: module.BonusSimulationListing,
  }))
)

const BonusSimulationForm = lazy(() =>
  import('./BonusSimulation/BonusSimulationForm').then(module => ({
    default: module.BonusSimulationForm,
  }))
)

const SimulationEmployeeListing = lazy(() =>
  import('./BonusSimulation/SimulationEmployeeListing').then(module => ({
    default: module.SimulationEmployeeListing,
  }))
)

const IncrementSimulationListing = lazy(() =>
  import('./IncrementSimulation/IncrementSimulationListing').then(module => ({
    default: module.IncrementSimulationListing,
  }))
)

const IncrementSimulationForm = lazy(() =>
  import('./IncrementSimulation/IncrementSimulationForm').then(module => ({
    default: module.IncrementSimulationForm,
  }))
)

const StaffAppraisalManagementRoutes = [
  {
    props: { exact: true, path: '/ModerationTable/ModerationTable' },
    component: <ModerationTable />,
  },
  {
    props: { exact: true, path: '/StaffKpi/StaffKpiListing' },
    component: <StaffKpiListing />,
  },
  {
    props: { exact: true, path: '/StaffKpi/StaffVersion' },
    component: <StaffVersion />,
  },
  {
    props: { exact: true, path: '/StaffKpi/StaffKpiForm' },
    component: <StaffKpiForm />,
  },
  {
    props: { exact: true, path: '/StaffKpi/StaffKpiDetailForm' },
    component: <StaffKpiDetailForm />,
  },
  {
    props: { exact: true, path: '/StaffKpi/ScaleForm' },
    component: <ScaleForm />,
  },
  {
    props: { exact: true, path: '/StaffAppraisal/StaffEmployeeApprasialListing' },
    component: <StaffEmployeeApprasialListing />,
  },
  {
    props: { exact: true, path: '/StaffAppraisal/Appraisal/StaffAppraisalListing' },
    component: <StaffAppraisalListing />,
  },
  {
    props: { exact: true, path: '/StaffAppraisal/Appraisal/StaffAppraisalForm' },
    component: <StaffAppraisalForm />,
  },
  {
    props: { exact: true, path: '/StaffAppraisal/Appraisal/StaffAppraisalInnerForm' },
    component: <StaffAppraisalInnerForm />,
  },
  {
    props: { exact: true, path: '/StaffPerformanceAnalysis/StaffPerformanceAnalysisSummary' },
    component: <StaffPerformanceAnalysisSummary />,
  },
  {
    props: { exact: true, path: '/BonusSimulation/BonusSimulationListing' },
    component: <BonusSimulationListing />,
  },
  {
    props: { exact: true, path: '/BonusSimulation/BonusSimulationForm' },
    component: <BonusSimulationForm />,
  },
  {
    props: { exact: true, path: '/BonusSimulation/SimulationEmployeeListing' },
    component: <SimulationEmployeeListing />,
  },
  {
    props: { exact: true, path: '/IncrementSimulation/IncrementSimulationListing' },
    component: <IncrementSimulationListing />,
  },
  {
    props: { exact: true, path: '/IncrementSimulation/IncrementSimulationForm' },
    component: <IncrementSimulationForm mode='Add' />,
  },
]

export default StaffAppraisalManagementRoutes